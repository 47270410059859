export function genUID() {
  return Math.random().toString(36).substr(2, 9)
}

export function clearEmptyArrayValues<T>(arr: T[] | undefined): TSReset.NonFalsy<T>[] | undefined {
  return arr?.filter(Boolean)
}

export function arrayInLength(length: number) {
  return Array.from({ length }, (v, i) => i + 1)
}

export function isValidNumber(value: any) {
  return /^\d+$/.test(value)
}

export function toUnit(v: any): any {
  return v === null ? null : isValidNumber(v) ? v + "px" : v
}

export function isNullOrUndefined<T extends any = any>(val: T): boolean {
  return val === null || val === undefined
}

export function isNumeric(n: any) {
  if (isNullOrUndefined(n)) return false

  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function parseCommasToNumber(str: string) {
  return parseFloat(str?.replace(/,/g, ""))
}

export function intlFormatNumber(num: number) {
  return Intl.NumberFormat("en", {
    notation: "compact",
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(num)
}

export function numberToFixed(n: number, toFixed = 2) {
  return Number(n ?? 0).toLocaleString(undefined, {
    minimumFractionDigits: Number(toFixed),
    maximumFractionDigits: Number(toFixed),
  })
}

export function toCash(val: any, currency = "$", toFixed = 0) {
  let value = Number(val)

  if (isNaN(value)) value = 0

  if (value < 0) {
    return `-${currency}${numberToFixed(Math.abs(value), toFixed)}`
  }

  return `${currency}${numberToFixed(value, toFixed)}`
}

export function toPercent(p: number | string | undefined | null, multiplyBy100 = false, toFixed = 2) {
  const fixedParsed = parseFloat(p as string).toFixed(toFixed)

  // check if parsed value is NaN or -0
  if (isNaN(p as number) || isNaN(parseFloat(p as string)) || fixedParsed === "-0") return 0 + "%"
  // @ts-ignore
  else if (multiplyBy100) return parseFloat(p * 100).toFixed(toFixed) + "%"

  return fixedParsed + "%"
}

export function toUnix(date = new Date()): number {
  return Math.floor(new Date(date).getTime() / 1000)
}

export function fromUnix(date = new Date().getTime(), format = "YYYY-MM-DD") {
  const dayjs = useDayjs()

  return dayjs.unix(date).format(format)
}

export const genKeyForDeviceUpdates = reactify((name?: any) => {
  const { isMobile } = useDisplay()
  const { isRtl } = useRtl()

  return [isMobile.value, isRtl.value, name].join("_")
})

export function clampNum(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max)
}

export function toArray<T>(input: T | T[]) {
  if (isNullOrUndefined(input)) return []
  return Array.isArray(input) ? input : [input]
}

export function toArrayResolver(arr: any[]) {
  const isArray = Array.isArray(arr)
  const newArray = isArray ? arr : [arr]

  return [isArray, newArray]
}

const nonDigitsRegExp = /\D/g
export function removeNonDigitsChars(str: Nullable<string>): string {
  return String(str).replace(nonDigitsRegExp, "")
}

export function removeWhitespace(str: Nullable<string>) {
  return String(str)?.replace(/\s+/g, " ").trim()
}
