<template>
  <UiButton class="underline" unset-all @click="handlePopup">
    <slot />
  </UiButton>
</template>

<script setup lang="tsx">
import { NuxtImg } from "#components"
import { PricingProductEnum } from "#imports"

const props = defineProps<{
  product: PricingProductEnum
}>()

const { t } = useI18n({ useScope: "local" })
const { products } = useProducts()
const popup = usePopup()

const productTitle = computed(() => products.value?.[props.product || PricingProductEnum.Stocks].label)

const Body: FunctionalComponent = () => (
  <div class="mt-2 flex flex-col gap-4">
    <p class="text-sm text-black/50" v-html-format={t("description", { product: productTitle.value })} />
    <NuxtImg class="h-4 w-16" src={getImageUrl("icons/seg-logo.png")} />
  </div>
)

function handlePopup() {
  return popup.open({
    title: t("title"),
    component: Body,
  })
}
</script>

<i18n lang="json">
{
  "he": {
    "title": "רפיניטיב",
    "description": "הלוגו של רפיניטיב ורפיניטיב הם סימנים מסחריים של רפיניטיב והחברות הקשורות לה בארצות הברית ובמדינות אחרות ומשמשים כאן ברישיון. שימוש, שכפול או מכירה של שירות זה או נתונים הכלולים במסמך זה, למעט כפי שמתואר בהסכם המנוי ל - {product}, אסורים בהחלט.\n זכויות יוצרים Refinitiv, 2021©. כל הזכויות שמורות."
  },
  "en": {
    "title": "Refinitive",
    "description": "The Refinitiv Logo and Refinitiv are trademarks of Refinitiv and its affiliated companies in the United States and other countries and used herein under license. Use, duplication, or sale of this service, or data contained herein, except as described in the {product} subscription agreement, is strictly prohibited.\nCopyright ©Refinitiv, 2021. All Rights Reserved."
  }
}
</i18n>
