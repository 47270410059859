import { type UseI18nOptions } from "vue-i18n"

type UseRtlOptions = {
  i18nInstance?: ReturnType<typeof useI18n>
  i18nOptions?: UseI18nOptions
}

export function useRtl(options?: UseRtlOptions) {
  const i18n = options?.i18nInstance ?? useI18n(options?.i18nOptions)

  const direction = computed(() => i18n.localeProperties.value.dir as "rtl" | "ltr")
  const isRtl = computed(() => direction.value === "rtl")

  return {
    direction,
    isRtl,
  }
}
