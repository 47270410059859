import { SnackbarMessage } from "@finq/ui/types/snackbar"

export const useSnackbar = () => {
  const snackbarMessages = useState<SnackbarMessage[]>("snackbar:messages", () => [])

  const open = (snackbar: SnackbarMessage) => {
    const id = snackbar.id ?? genUID()
    const fixedMessage = { ...snackbar, id, close: () => close(id) }

    if (typeof snackbar.action === "function") {
      fixedMessage.action = { action: snackbar.action }
    }

    if (typeof snackbar.cancel === "function") {
      fixedMessage.cancel = { action: snackbar.cancel }
    }

    snackbarMessages.value.push(fixedMessage)
    snackbar?.callback?.()

    return { id: fixedMessage.id!, close: fixedMessage.close! } // Useful to prevent the next popup from disappearing
  }

  const close = (id: string) => {
    if (!id) return
    snackbarMessages.value = snackbarMessages.value.filter((message) => message.id !== id)
  }

  return {
    open,
    close,
    snackbarMessages,
  }
}
