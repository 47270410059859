export const cookiesConstants = {
  locale: "i18n_redirected",
  cookiesAccepted: "finq_cookies_accepted",
} as const

export const sessionStorageConstants = {
  finqProducts: "finq_products",
  temp: "temp_storage",
} as const

export const queriesConstants = {
  cms: "cms",
} as const

export const dateFormats = {
  llLT: "ll (LT)",
  ll: "ll",
} as const

export const regexPatterns = {
  // For some reason not compatible in iOS, using validator.isEmail method instead
  // email: /^(?=[a-zA-Z0-9])([a-zA-Z0-9\._+\-]+)@[a-zA-Z0-9]+(?!.*[\!@#\$%\^&\*\(\)\[\];:'\"<>\?/\|\\`~]{2,})(?!.*[\.,]{2,})(?!.*\.{2,}$)(?!.*\.{2,}\.)(?!.*\.[^\s@]{1,1}\.)(?!.*\.[^\s@]{1,1}$)(?!.*\.\.)[^\s@]+(?:\.[a-zA-Z0-9]{2,})+(?<![\.,!@#\$%\^&\*\(\)\[\];:'\",<>\?/\|\\`~])$/,
  /**
    (?=.*[a-z]): Ensures at least one lowercase letter.
    (?=.*[A-Z]): Ensures at least one uppercase letter.
    (?=.*[0-9]): Ensures at least one digit.
    (?=.*[!@#$%^&?]): Ensures at least one special character (from the set !@#$%^&?).
    [!@#$%^&?A-Za-z\d]{8,}: Matches the entire password, which must be at least 8 characters long, including allowed special characters, letters, and digits.
   */
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&?])[!@#$%^&? A-Za-z\d]{8,}$/,
} as const

export const FINQ_ISRAEL_DOMAIN = "finqai.co.il" as const
