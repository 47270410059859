import {
  type InferOutput,
  array,
  nullish,
  number,
  object,
  optional,
  picklist,
  pipe,
  string,
  transform,
} from "valibot"

export const PricingProductEnum = {
  Pensions: "PRODUCT_PENSION",
  Stocks: "PRODUCT_STOCK",
  Funds: "PRODUCT_FUND",
} as const

export const SupportedProductsInHebrewOnly = [PricingProductEnum.Pensions] as const

export type PricingProductEnum = (typeof PricingProductEnum)[keyof typeof PricingProductEnum]

export type PricingProductTabs = {
  id: PricingProductEnum
  label: string
  value: PricingProductEnum
  color: (typeof UiPricingPlans)["COLORS"][keyof (typeof UiPricingPlans)["COLORS"]]
}

export namespace UiPricingPlans {
  export const COLORS = {
    [PricingProductEnum.Stocks]: {
      base: "#005CE6",
      gradient: "linear-gradient(135deg, #46A5FF 0%, #005EEB 100%)",
      mobileGradient: "linear-gradient(180deg, #0080FF 0%, #005CE5 100%)",
      filter: "drop-shadow(1px 1.5px 1.5px rgba(150, 180, 218, 1))",
      outline: "3px solid rgba(31, 133, 249, 0.20)",
    },
    [PricingProductEnum.Funds]: {
      base: "#14B81F",
      gradient: "linear-gradient(135deg, #71E65F 0%, #1EBE0F 100%)",
      mobileGradient: "linear-gradient(180deg, #64E152 0%, #15C115 100%)",
      filter: "drop-shadow(1px 1.5px 1.5px rgba(160, 211, 156, 0.40))",
      outline: "3px solid rgba(108, 235, 91, 0.30)",
    },
    [PricingProductEnum.Pensions]: {
      base: "#9935FD",
      gradient: "linear-gradient(135deg, #D787FF 0%, #A541F5 100%)",
      mobileGradient: "linear-gradient(180deg, #C478F8 0%, #A14CF6 100%)",
      filter: "drop-shadow(1px 1.5px 1.5px rgba(150, 61, 245, 0.30))",
      outline: "3px solid rgba(204, 133, 255, 0.25)",
    },
  } as const

  export const BADGE = {
    [PricingProductEnum.Stocks]: {
      color: "#0080FF",
      backgroundColor: "rgba(0, 128, 255, 0.08)",
    },
    [PricingProductEnum.Pensions]: {
      color: "#AF55F0",
      backgroundColor: "rgba(215, 135, 255, 0.10)",
      border: "1px solid rgba(215, 135, 255, 0.20)",
    },
  } as const
}

export namespace PricingPlanTypes {
  export const CurrencyEnum = {
    ILS: "ILS",
    USD: "USD",
  } as const

  export const PlanCode = {
    DIY: "DIY",
    MANAGED: "MANAGED",
    DIY_AGENTS: "DIY_AGENTS",
    DIY_LIGHT: "DIY_LIGHT",
    DIY_PRO: "DIY_PRO",
  } as const

  export const PlanTypes = {
    SUBSCRIPTION: "SUBSCRIPTION",
    MANAGED: "MANAGED",
    UPCOMING: "UPCOMING",
  } as const

  export const PlanStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
    UPCOMING: "UPCOMING",
  } as const

  export const PlanPeriodType = {
    ANNUAL: "ANNUAL",
    MONTHLY: "MONTHLY",
    FEES: "FEES",
    ONETIME: "ONETIME",
    FREE: "FREE",
    NONE: "NONE",
  } as const

  export const UserPlanStatus = {
    SUBSCRIBED: "SUBSCRIBED",
    WAITING_LIST: "WAITING_LIST",
    UPGRADEABLE: "UPGRADEABLE",
    DOWNGRADEABLE: "DOWNGRADEABLE",
    NONE: "NONE",
  } as const

  // ---------------------------- SECHMAS -------------------------- //

  export const pricingPlanPricing = object({
    id: nullish(number()),
    type: nullish(picklist(Object.values(PricingPlanTypes.PlanPeriodType))),
    currency: nullish(picklist(Object.values(PricingPlanTypes.CurrencyEnum))),
    price: nullish(number()),
    expiryDate: nullish(
      pipe(
        string(),
        transform((value) => Math.round(+new Date(value) / 1000))
      )
    ),
    renewalDate: nullish(
      pipe(
        string(),
        transform((value) => Math.round(+new Date(value) / 1000))
      )
    ),
    pricingPlanStatus: nullish(picklist(Object.values(PricingPlanTypes.UserPlanStatus))),
  })

  export const pricingProductPlansSchema = object({
    code: picklist(Object.values(PlanCode)),
    status: picklist(Object.values(PricingPlanTypes.PlanStatus)),
    pricing: array(pricingPlanPricing),
    displayOrder: optional(number()),
  })

  export const pricingProductResponseSchema = object({
    id: picklist(Object.values(PricingProductEnum)),
    plans: array(pricingProductPlansSchema),
  })
}

// -------------------------- /v1/products-temp -------------------------- //
export const CurrencySymbols = {
  [PricingPlanTypes.CurrencyEnum.ILS]: "₪",
  [PricingPlanTypes.CurrencyEnum.USD]: "$",
} as const
export type CurrencySymbols = keyof typeof CurrencySymbols

export type PricingProductResponse = InferOutput<typeof PricingPlanTypes.pricingProductResponseSchema>
export type PricingPlanPricing = InferOutput<typeof PricingPlanTypes.pricingPlanPricing>
export type PricingPlan = InferOutput<typeof PricingPlanTypes.pricingProductPlansSchema>

export type PlanCode = (typeof PricingPlanTypes.PlanCode)[keyof typeof PricingPlanTypes.PlanCode]
export type PlanTypes = (typeof PricingPlanTypes.PlanTypes)[keyof typeof PricingPlanTypes.PlanTypes]
export type PlanStatus = (typeof PricingPlanTypes.PlanStatus)[keyof typeof PricingPlanTypes.PlanStatus]
export type PlanCurrencyEnum =
  (typeof PricingPlanTypes.CurrencyEnum)[keyof typeof PricingPlanTypes.CurrencyEnum]
export type PlanPeriodType =
  (typeof PricingPlanTypes.PlanPeriodType)[keyof typeof PricingPlanTypes.PlanPeriodType]
