import type { RouterConfig } from "@nuxt/schema"

import { parseQuery, stringifyQuery } from "../lib/queryParams"

const config: RouterConfig = {
  stringifyQuery,
  parseQuery,

  scrollBehaviorType: "smooth",
}

export default config
