export async function useUserBrowser() {
  const { UAParser } = await import("ua-parser-js")

  const parser = new UAParser()
  const browser = parser.getBrowser()

  return {
    isSafari: browser.name?.toLowerCase()?.includes("safari"),
    isChrome: browser.name?.toLowerCase()?.includes("chrome"),
  }
}
