import { snakeCase } from "lodash-es"

export function genDataPosition() {
  const instance = getCurrentInstance()

  return [instance?.parent?.parent?.type.__name, instance?.parent?.type.__name]
    .map(snakeCase)
    .filter((val) => val !== "default" && Boolean(val))
    .join(".")
}
