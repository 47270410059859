import { useQueryClient } from "@tanstack/vue-query"

export function useAppInit(): void {
  useMe() // fetch user data
  useInitAnalytics() // track page views
  useIdleTracking() // track idle time
  useInitUtmCookies() // track utm cookies
  useResetQueriesOnUserChange() // reset queries on user change
}

function useInitAnalytics() {
  const cfg = useAppConfig()
  const gtm = useGtm()

  tryOnMounted(() => {
    if (!cfg.analytics?.enabled || gtm.isFirstTime.value) return

    gtm.consentUpdate({ approved: gtm.cookiesAccepted.value })
  })
}

function useInitUtmCookies() {
  const { ref, adId, lastClickRef } = useUtmCookies()
  const { query } = useRoute()

  watchEffect(() => {
    if (!isServer && document.referrer) lastClickRef.value = new URL(document.referrer).hostname
    if (query.ref) ref.value = String(query.ref)
    if (query.ad_id) adId.value = String(query.ad_id)
  })
}

function useResetQueriesOnUserChange() {
  const { isAuthenticated } = useUser()
  const queryClient = useQueryClient()

  watch(isAuthenticated, () => {
    queryClient.resetQueries()
  })
}
