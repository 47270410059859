export function clg(...args: Parameters<typeof console.log>) {
  return console.log(...args.map((arg) => (typeof arg === "object" ? jsonClean(arg) : arg)))
}

/** Removes undefined values from an object */
type Clean<T> = {
  [P in keyof T as {} extends { [K in P]: T[P] } ? never : P]: Exclude<T[P], undefined>
}

export function jsonClean<T>(data: T): Clean<T> {
  return JSON.parse(JSON.stringify(data)) as Clean<T>
}

export function jsonCompare(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b)
}
