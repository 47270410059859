import mitt from "mitt"

export default defineNuxtPlugin({
  name: "eventBus",
  parallel: true,
  setup() {
    const emitter = mitt()

    return {
      provide: {
        eventBus: {
          $on: emitter.on,
          $off: emitter.off,
          $emit: emitter.emit,
        },
      },
    }
  },
})
