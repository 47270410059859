import { WebAuth } from "auth0-js"
import { isDevelopment } from "std-env"

import { fetchWithRecaptcha } from "../auth/useReCaptchaHandler"
import { BaseApiService, KyOptions } from "./api.service"

const headers = {
  "cache-control": "no-cache",
  "Content-Type": "application/json",
}

export interface BaseCredentials {
  email: string
  password: string
}
export interface LoginCredentials extends BaseCredentials {}
export interface SignupCredentials extends BaseCredentials {
  firstName: string
  lastName: string
}
export interface VerifyCredentials extends LoginCredentials {
  code: string
}
export interface ForgotPasswordCredentials extends Pick<LoginCredentials, "email"> {}
export interface ResendVerificationCredentials extends Pick<LoginCredentials, "email"> {}

class AuthServiceRaw extends BaseApiService {
  private _webAuth: WebAuth | null = null

  public login = async (data: LoginCredentials, config: KyOptions = {}) => {
    const { saveUser } = useUser()
    const res = await this.instance.post("/auth/login", data, config).then(this.getData)

    return saveUser(res)
  }

  public signup = async (data: SignupCredentials, config: KyOptions = {}) => {
    const { password, ...signupData } = data
    const { mergeUser } = useUser()

    const res = await this.instance.post("/auth/signup", addUtmObject(data), config).then(this.getData)

    return mergeUser(res)
  }

  public verifyUser = async (data: VerifyCredentials, config: KyOptions = {}) => {
    const { password, ...signupData } = data
    const { userExists, mergeUser, saveUser } = useUser()

    const res = await this.instance.post("/auth/verify", data, config).then(this.getData)

    if (userExists.value) {
      return mergeUser({ ...signupData, ...res })
    }

    return saveUser(res)
  }

  public resendVerification = async (data: ResendVerificationCredentials, config: KyOptions = {}) => {
    return this.instance.post("/auth/resend-verification", data, config).then(this.getData)
  }

  public forgotPassword = async (data: ForgotPasswordCredentials, config: KyOptions = {}) => {
    const res = await this.instance.post("/auth/forgotpassword", data, config).then(this.getData)

    return res
  }

  public loginWithRecaptcha = fetchWithRecaptcha<LoginCredentials>(this.login)
  public signupWithRecaptcha = fetchWithRecaptcha<SignupCredentials>(this.signup)
  public verifyUserWithRecaptcha = fetchWithRecaptcha<VerifyCredentials>(this.verifyUser)
  public forgotPasswordWithRecaptcha = fetchWithRecaptcha<ForgotPasswordCredentials>(this.forgotPassword)
  public resendVerificationWithRecaptcha = fetchWithRecaptcha<ResendVerificationCredentials>(
    this.resendVerification
  )

  public refreshToken = async (refreshToken: string, config: KyOptions = {}) => {
    const res = await this.instance.post("/auth/refreshtoken", { refreshToken }, config).then(this.getData)

    return res
  }

  public getUserFromSocialLogin = async (accessToken: string, config: KyOptions = {}) => {
    const { saveUser } = useUser()

    const { data: res } = await this.instance.post("/auth/social", addUtmObject({}), {
      headers: { Authorization: `Bearer ${accessToken}` },
    })

    return saveUser(res.data)
  }

  private _getWebAuth = async () => {
    const config = useConfig()
    // Lazy import the Auth0 SDK into the app
    const auth0 = await import("auth0-js").then((module) => module.default)

    // If the WebAuth instance is already available, return it immediately to avoid recreating it
    if (this._webAuth) return this._webAuth

    // Create an instance of the SDK
    this._webAuth = new auth0.WebAuth({
      domain: config.NUXT_PUBLIC_AUTH0_DOMAIN!,
      clientID: config.NUXT_PUBLIC_AUTH0_CLIENT_ID!,
      responseType: "token",
      scope: "openid profile email offline_access update:current_user_identities",
      audience: config.NUXT_PUBLIC_AUTH0_AUDIENCE,
    })

    return this._webAuth
  }

  public preloadSocialPopup = async () => {
    const webAuth = await this._getWebAuth()

    try {
      // Returns true because sometimes `preload` returns null when the popup is blocked by the browser. which is normal.
      return webAuth.popup.preload({}) || true
    } catch (e) {
      // If the popup is blocked, the error is thrown. We catch it and return true.
      return true
    }
  }

  public loginWithSocialPopup = async (connection: any, callback: any) => {
    const config = useConfig()
    const { auth } = useUser()
    const { isMobile } = useDisplay()
    const redirectUri = isDevelopment ? "http://localhost:3000" : config.NUXT_PUBLIC_SITE_URL!

    auth.value.popupOpen = true

    try {
      const webAuth = await this._getWebAuth()

      if (isMobile.value) {
        return webAuth.authorize({
          connection,
          redirectUri,
        })
      } else {
        return webAuth.popup.authorize(
          {
            domain: config.NUXT_PUBLIC_AUTH0_DOMAIN!,
            owp: true,
            connection,
            responseType: "token",
            redirectUri,
          },
          (err, authResult) => {
            console.log("Social login error", err)
            console.log("Auth Result", authResult)

            if (err) {
              callback(err)
              return
            }

            this.getUserFromSocialLogin(authResult.accessToken!)
              .then((res) => callback(null, res))
              .catch((err) => callback(err))
          }
        )
      }
    } catch (e) {
      console.error(`0Auth error at ${connection}:`, e)
      callback(e)
    } finally {
      auth.value.popupOpen = false
    }
  }
}

/** Exporting a singleton object to entire app */
export const AuthService = new AuthServiceRaw()
