import { camelCase } from "lodash-es"

const isNotNullOrUndefined = (val: any) => isNullOrUndefined(val) === false

type ClickElement = "button" | "input" | "link" | "label" | "submit"

type ClickAreaType =
  | "icon"
  | "text"
  | "image"
  | "cta"
  | "section"
  | "header"
  | "footer"
  | "sidebar"
  | "nav"
  | "breadcrumb"
  | "hero"
  | "card"
  | "list"
  | "table"
  | "form"
  | "modal"
  | "dropdown"
  | "tab"
  | "menu"

type PossibleClickIdHelpers =
  | RoutesNamesList
  | ClickAreaType
  | ClickElement
  | (string & {})
  | number
  | boolean
  | null
  | undefined

export function genClickId(requiredName: string, ...overloads: PossibleClickIdHelpers[]) {
  const mappedOverloads = [requiredName, ...overloads].filter(isNotNullOrUndefined).map((arg) => {
    if (typeof arg === "string") {
      return camelCase(arg)
    }

    return arg
  })

  return mappedOverloads.join("_")
}

export function genReferringFeature() {
  const inst = getCurrentInstance()

  const combinedComponentIds = [
    inst?.type.__name, // current component
    inst?.type.name, // current component
    inst?.parent?.type.__name, // parent component
    String(inst?.parent?.uid), // parent component
  ]
    .filter(isNotNullOrUndefined)
    .map(camelCase)
    .join(":")

  return combinedComponentIds
}
