<script lang="ts">
export const modalVariants = cva(
  "z-[60] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
  {
    variants: {
      backdrop: {
        default: "fixed inset-0 bg-black/40",
        white: "backdrop-blur-sm fixed inset-0 bg-white/60 md:bg-black/40",
        none: "",
      },
    },
    defaultVariants: {
      backdrop: "default",
    },
  }
)
</script>

<script setup lang="ts">
import { type VariantProps, cva } from "class-variance-authority"
import { DialogOverlay, type DialogOverlayProps } from "radix-vue"

export interface UiDialogOverlayProps extends DialogOverlayProps {
  class?: ClassValue
  backdrop?: VariantProps<typeof modalVariants>["backdrop"]
}

const props = defineProps<UiDialogOverlayProps>()
</script>

<template>
  <DialogOverlay v-bind="props" :class="cn(modalVariants({ backdrop: props.backdrop }), props.class)" />
</template>
