import type { DirectiveBinding } from "vue"

type ObserveHandler = (
  isIntersecting: boolean,
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => void

interface HTMLElementWithObserve extends HTMLElement {
  _observe?: Record<
    number,
    | {
        init: boolean
        observer: IntersectionObserver
      }
    | undefined
  >
}

export interface ObserveDirectiveBinding extends Omit<DirectiveBinding, "modifiers" | "value"> {
  value?: ObserveHandler | { handler: ObserveHandler; options?: IntersectionObserverInit }
  modifiers: {
    once?: boolean
    quiet?: boolean
  }
}

const unmounted = (el: HTMLElementWithObserve, binding: ObserveDirectiveBinding) => {
  const observe = el._observe?.[binding.instance!.$.uid]

  if (!observe) return

  observe.observer.unobserve(el)
  delete el._observe![binding.instance!.$.uid]
}

export default defineNuxtPlugin({
  name: "intersect",
  parallel: true,
  setup(nuxtApp) {
    nuxtApp.vueApp.directive("intersect", {
      mounted(el: HTMLElementWithObserve, binding: ObserveDirectiveBinding) {
        const modifiers = binding.modifiers || {}
        const value = binding.value
        const { handler, options } = typeof value === "object" ? value : { handler: value, options: {} }

        const observer = new IntersectionObserver(
          (entries: IntersectionObserverEntry[] = [], observer: IntersectionObserver) => {
            const _observe = el._observe?.[binding.instance!.$uid as keyof typeof el._observe]

            if (!_observe) return // Just in case, should never fire

            const isIntersecting = entries.some((entry) => entry.isIntersecting)

            // If is not quiet or has already been
            // initialized, invoke the user callback
            if (
              handler &&
              (!modifiers.quiet || _observe.init) &&
              (!modifiers.once || isIntersecting || _observe.init)
            ) {
              handler(isIntersecting, entries, observer)
            }

            if (isIntersecting && modifiers.once) unmounted(el, binding)
            else _observe.init = true
          },
          options
        )

        el._observe = Object(el._observe)
        el._observe![binding.instance!.$uid as keyof typeof el._observe] = { init: false, observer }

        observer.observe(el)
      },

      unmounted,
    })
  },
})
