<template>
  <span class="finq-icon">
    <component
      v-bind="$attrs"
      :is="icon"
      :title="alt ?? name"
      :style="{ '--icon-size': sizeIsNumber ? `${size}px` : null }"
      :class="
        cn([
          props.class,
          sizeIsNumber ? null : iconSizeVariant,
          {
            'finq-icon--size': sizeIsNumber,
            'finq-icon--fill': !filled,
            'finq-icon--stroke': stroked,
            'finq-icon--font-size': fontSized,
          },
        ])
      "
    />
  </span>
</template>

<script lang="ts">
import { VariantProps, cva } from "class-variance-authority"
import { isNumber } from "lodash-es"

export const nuxtIconVariants = cva("inline-flex", {
  variants: {
    iconSize: {
      xxs: "w-xxs h-xxs",
      xs: "w-xs h-xs",
      s: "w-s h-s",
      24: "w-6 h-6",
      m: "w-m h-m",
      mx: "w-mx h-mx",
      l: "w-l h-l",
      xl: "w-xl h-xl",
    },
  },
  defaultVariants: {
    iconSize: "xs",
  },
})

export interface BaseIconProps {
  icon: Record<string, unknown>
  size?: number | VariantProps<typeof nuxtIconVariants>["iconSize"]
  class?: ClassValue
  stroked?: boolean
  filled?: boolean
  fontSized?: boolean
  alt?: string
  name?: string
}
</script>

<script setup lang="ts">
// file imported from https://github.com/cpsoinos/nuxt-svgo/blob/main/src/runtime/components/finq-icon.vue
// with modifications

defineComponent({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<BaseIconProps>(), {})

const sizeIsNumber = computed(() => isNumber(props.size))

const iconSizeVariant = computed(() => {
  if (!props.size || typeof props.size === "number") return null
  return nuxtIconVariants({ iconSize: props.size })
})
</script>

<style>
.finq-icon--size {
  width: var(--icon-size);
  height: var(--icon-size);
}
.finq-icon--font-size {
  width: 1em;
  height: 1em;
}
.finq-icon--fill {
  fill: currentColor;
}
.finq-icon--fill * {
  fill: inherit;
}
.finq-icon--stroke,
.finq-icon--stroke * {
  stroke: currentColor;
}
</style>
