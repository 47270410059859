<template>
  <UiTransition v-if="requiredLoading" :name="transition" mode="out-in">
    <div v-if="debouncedLoading" :class="classNames.container">
      <Line v-for="index in arrayInLength(repeat || 1)" :key="index" />
    </div>

    <component v-else v-bind="$attrs" :is="slotTag || 'div'" :class="classNames.slot">
      <slot />
    </component>
  </UiTransition>

  <component v-else v-bind="$attrs" :is="slotTag || 'div'" :class="classNames.slot">
    <slot />
  </component>
</template>

<script setup lang="ts">
export interface UiSkeletonClasses {
  container: ClassValue
  line: ClassValue
  slot: ClassValue
}

export interface UiSkeletonProps {
  loading?: boolean
  repeat?: number
  class?: ClassValue
  classes?: Partial<UiSkeletonClasses>
  transition?: keyof typeof FinqTransitions
  slotTag?: keyof HTMLElementTagNameMap
}

const props = withDefaults(defineProps<UiSkeletonProps>(), { loading: undefined, transition: "fade" })
// Makes sure loading wont flicker too much if response returns too fast
const debouncedLoading = refDebounced(toRef(props, "loading"), 300)

const requiredLoading = computed(() => !isNullOrUndefined(props.loading))

const Line = (p: any, ctx: any) => {
  return h("div", { class: classNames.value.line }, "#")
}

const classNames = computed((): UiSkeletonClasses => {
  return {
    container: cn(["flex w-full flex-col gap-2", props.classes?.container]),
    slot: cn(props.classes?.slot),
    line: cn(
      "bg-skeleton w-full animate-pulse rounded-sm text-sm text-transparent",
      props.classes?.line,
      props.class!
    ),
  }
})
</script>
