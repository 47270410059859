import type { NuxtI18nOptions } from "@nuxtjs/i18n"
import type { PreloadedFont } from "nuxt-font-preload/dist/runtime/types/index"
import type { LocaleObject } from "vue-i18n-routing"

export const AppBreakpoints = {
  "2xl": 1920,
  xl: 1535,
  lg: 1360,
  md: 960,
  sm: 480,
} as const

export const i18nCommonLocaleMap: Record<string, LocaleObject> = {
  en: {
    code: "en",
    name: "English",
    dir: "ltr",
    iso: "en-US",
  },
  he: {
    code: "he",
    name: "Hebrew",
    dir: "rtl",
    iso: "he-IL",
  },
}

type LocaleConfig = (typeof i18nCommonLocaleMap)[keyof typeof i18nCommonLocaleMap]
type LocaleCode = keyof typeof i18nCommonLocaleMap

export const getLocaleConfigByCode = (code: keyof typeof i18nCommonLocaleMap | string): LocaleConfig => {
  return i18nCommonLocaleMap[code as LocaleCode]
}

export const i18nConfig: NuxtI18nOptions = {
  compilation: { strictMessage: false, escapeHtml: false },
  lazy: true,
  baseUrl: "https://finqai.com",
  strategy: "no_prefix",
  defaultLocale: "en",

  langDir: "locales/",
  locales: [
    {
      ...i18nCommonLocaleMap["en"],
      files: [
        "en/common/accessibility.json",
        "en/common/errors.json",
        "en/common/meta.json",
        "en/common/common.json",
        "en/common/authentication.json",
        "en/profile/profile_page.json",
        "en/proposals/investments.json",
        // "en/profile/my_inv.json",
        "../../../../packages/ui/locales/en/common/common.json",
        "../../../../packages/kyc/locales/en/kyc.json",
        "../../../../packages/funds/locales/en/funds.json",
        "../../../../packages/stocks/locales/en/stocks.json",
        "../../../../packages/portfolios/locales/en/portfolios.json",
        "../../../../packages/portfolio-widget/locales/en/portfolio_widget.json",
      ],
    },
  ],
}

export const RagSansFontConfig: PreloadedFont = {
  fontFamily: "RAG-Sans",
  weights: [
    {
      weight: 300,
      sources: [
        { url: "/assets/fonts/RAG-Sans-1.1-Light.woff2", format: "woff2", preload: true },
        { url: "/assets/fonts/RAG-Sans-1.1-Light.woff", format: "woff" },
      ],
    },
    {
      weight: 400,
      sources: [
        { url: "/assets/fonts/RAG-Sans-1.1-Regular.woff2", format: "woff2", preload: true },
        { url: "/assets/fonts/RAG-Sans-1.1-Regular.woff", format: "woff" },
      ],
    },
    {
      weight: 500,
      sources: [
        { url: "/assets/fonts/RAG-Sans-1.1-Medium.woff2", format: "woff2", preload: true },
        { url: "/assets/fonts/RAG-Sans-1.1-Medium.woff", format: "woff" },
      ],
    },
    {
      weight: 600,
      sources: [
        { url: "/assets/fonts/RAG-Sans-1.1-SemiBold.woff2", format: "woff2", preload: true },
        { url: "/assets/fonts/RAG-Sans-1.1-SemiBold.woff", format: "woff" },
      ],
    },
    {
      weight: 800,
      sources: [
        { url: "/assets/fonts/RAG-Sans-1.1-ExtraBold.woff2", format: "woff2", preload: true },
        { url: "/assets/fonts/RAG-Sans-1.1-ExtraBold.woff", format: "woff" },
      ],
    },
  ],
  defaultOptions: {
    fontDisplay: "swap",
    ascentOverride: "80%",
  } as const,
}
