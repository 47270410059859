import { default as _91_46_46_46404_93dMgKFsN6Q6Meta } from "/usr/src/packages/error-handling/pages/[...404].vue?macro=true";
import { default as about4C1BTiVyMhMeta } from "/usr/src/apps/base/src/pages/about.vue?macro=true";
import { default as accessibility08XNiHGQ0oMeta } from "/usr/src/apps/base/src/pages/accessibility.vue?macro=true";
import { default as contactwZAZ1OhQATMeta } from "/usr/src/apps/base/src/pages/contact.vue?macro=true";
import { default as datasecurityk9e8JnurInMeta } from "/usr/src/apps/base/src/pages/datasecurity.vue?macro=true";
import { default as disclosureIQD52U35CiMeta } from "/usr/src/apps/base/src/pages/disclosure.vue?macro=true";
import { default as forgotpassword3Ou1x7Xjk4Meta } from "/usr/src/apps/base/src/pages/forgotpassword.vue?macro=true";
import { default as indexqksRg9oJ1WMeta } from "/usr/src/apps/base/src/pages/index.vue?macro=true";
import { default as loginYIEPQC6mhGMeta } from "/usr/src/apps/base/src/pages/login.vue?macro=true";
import { default as partnersnKP1uP9IicMeta } from "/usr/src/apps/base/src/pages/partners.vue?macro=true";
import { default as passwordresetawaitFAii2hbFCfMeta } from "/usr/src/apps/base/src/pages/passwordresetawait.vue?macro=true";
import { default as _91_91productId_93_93hqPFitOqkYMeta } from "/usr/src/packages/pricing/pages/pricing/[[productId]].vue?macro=true";
import { default as pricingOIBELzIw3pMeta } from "/usr/src/packages/pricing/pages/pricing.vue?macro=true";
import { default as privacyW7FWgOcv9fMeta } from "/usr/src/apps/base/src/pages/privacy.vue?macro=true";
import { default as infoFL8Bz5DpZJMeta } from "/usr/src/apps/base/src/pages/profile/info.vue?macro=true";
import { default as profilezv8G5W5pJQMeta } from "/usr/src/apps/base/src/pages/profile.vue?macro=true";
import { default as signupD0zLV4v9noMeta } from "/usr/src/apps/base/src/pages/signup.vue?macro=true";
import { default as finqfull2rvSY7bCOYMeta } from "/usr/src/packages/stocks/pages/stocks/depot/finqfull.vue?macro=true";
import { default as indexD0CTvv9iYrMeta } from "/usr/src/packages/stocks/pages/stocks/depot/index.vue?macro=true";
import { default as depotozn4ObMpKAMeta } from "/usr/src/packages/stocks/pages/stocks/depot.vue?macro=true";
import { default as indexmzXPjhq5EgMeta } from "/usr/src/packages/stocks/pages/stocks/index.vue?macro=true";
import { default as indexPDJrGGTxBuMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue?macro=true";
import { default as returnsnxcTGiYrnyMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue?macro=true";
import { default as transactionslgjJ1jRxX6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue?macro=true";
import { default as _91portfolioType_93Lz5OTVGOUJMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue?macro=true";
import { default as _91symbol_93guU4zbYWVFMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details/[symbol].vue?macro=true";
import { default as detailsxa2DsXpMPrMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details.vue?macro=true";
import { default as indexsNto0OwKRYMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/index.vue?macro=true";
import { default as _91sectorId_93oZzOWsL8HcMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/sectors/[sectorId].vue?macro=true";
import { default as indexvuxyYCf9xrMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/sectors/index.vue?macro=true";
import { default as sectorsLTaJFptYjnMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/sectors.vue?macro=true";
import { default as indexRIwpw4c1lXMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue?macro=true";
import { default as sectors74tcQjPqt4Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue?macro=true";
import { default as snp500mPrgrMS26sMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue?macro=true";
import { default as portfolioszi0VENqbE6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios.vue?macro=true";
import { default as searchT1B2Y8KjdfMeta } from "/usr/src/packages/stocks/pages/stocks/search.vue?macro=true";
import { default as top20QVDMEgSsSmMeta } from "/usr/src/packages/stocks/pages/stocks/top20.vue?macro=true";
import { default as stocksYNZBSUl7vEMeta } from "/usr/src/packages/stocks/pages/stocks.vue?macro=true";
import { default as callback0Nh93rez4dMeta } from "/usr/src/apps/base/src/pages/subscribe/callback.vue?macro=true";
import { default as termsofusel5AzRHu0H7Meta } from "/usr/src/apps/base/src/pages/termsofuse.vue?macro=true";
import { default as trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta } from "/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue?macro=true";
import { default as verify2fhYk5060fMeta } from "/usr/src/apps/base/src/pages/verify.vue?macro=true";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    meta: _91_46_46_46404_93dMgKFsN6Q6Meta || {},
    component: () => import("/usr/src/packages/error-handling/pages/[...404].vue")
  },
  {
    name: "about",
    path: "/about",
    meta: about4C1BTiVyMhMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/about.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/usr/src/apps/base/src/pages/accessibility.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactwZAZ1OhQATMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/contact.vue")
  },
  {
    name: "datasecurity",
    path: "/datasecurity",
    meta: datasecurityk9e8JnurInMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/datasecurity.vue")
  },
  {
    name: "disclosure",
    path: "/disclosure",
    component: () => import("/usr/src/apps/base/src/pages/disclosure.vue")
  },
  {
    name: "forgotpassword",
    path: "/forgotpassword",
    meta: forgotpassword3Ou1x7Xjk4Meta || {},
    component: () => import("/usr/src/apps/base/src/pages/forgotpassword.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexqksRg9oJ1WMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginYIEPQC6mhGMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/login.vue")
  },
  {
    name: "partners",
    path: "/partners",
    meta: partnersnKP1uP9IicMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/partners.vue")
  },
  {
    name: "passwordresetawait",
    path: "/passwordresetawait",
    meta: passwordresetawaitFAii2hbFCfMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/passwordresetawait.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/usr/src/packages/pricing/pages/pricing.vue"),
    children: [
  {
    name: "pricing-productId",
    path: ":productId?",
    meta: _91_91productId_93_93hqPFitOqkYMeta || {},
    component: () => import("/usr/src/packages/pricing/pages/pricing/[[productId]].vue")
  }
]
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyW7FWgOcv9fMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/privacy.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilezv8G5W5pJQMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/profile.vue"),
    children: [
  {
    name: "profile-info",
    path: "info",
    meta: infoFL8Bz5DpZJMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/profile/info.vue")
  }
]
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupD0zLV4v9noMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/signup.vue")
  },
  {
    name: stocksYNZBSUl7vEMeta?.name,
    path: "/stocks",
    meta: stocksYNZBSUl7vEMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks.vue"),
    children: [
  {
    name: depotozn4ObMpKAMeta?.name,
    path: "depot",
    meta: depotozn4ObMpKAMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot.vue"),
    children: [
  {
    name: "stocks-depot-finqfull",
    path: "finqfull",
    meta: finqfull2rvSY7bCOYMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot/finqfull.vue")
  },
  {
    name: "stocks-depot",
    path: "",
    meta: indexD0CTvv9iYrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot/index.vue")
  }
]
  },
  {
    name: "stocks",
    path: "",
    component: () => import("/usr/src/packages/stocks/pages/stocks/index.vue")
  },
  {
    name: portfolioszi0VENqbE6Meta?.name,
    path: "portfolios",
    meta: portfolioszi0VENqbE6Meta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios.vue"),
    children: [
  {
    name: _91portfolioType_93Lz5OTVGOUJMeta?.name,
    path: ":portfolioType()",
    meta: _91portfolioType_93Lz5OTVGOUJMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue"),
    children: [
  {
    name: "stocks-portfolios-portfolioType",
    path: "",
    meta: indexPDJrGGTxBuMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue")
  },
  {
    name: "stocks-portfolios-portfolioType-returns",
    path: "returns",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue")
  },
  {
    name: "stocks-portfolios-portfolioType-transactions",
    path: "transactions",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue")
  }
]
  },
  {
    name: "stocks-portfolios-details",
    path: "details",
    meta: detailsxa2DsXpMPrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details.vue"),
    children: [
  {
    name: "stocks-portfolios-details-symbol",
    path: ":symbol()",
    meta: _91symbol_93guU4zbYWVFMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details/[symbol].vue")
  }
]
  },
  {
    name: "stocks-portfolios",
    path: "",
    meta: indexsNto0OwKRYMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/index.vue")
  },
  {
    name: sectorsLTaJFptYjnMeta?.name,
    path: "sectors",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors.vue"),
    children: [
  {
    name: "stocks-portfolios-sectors-sectorId",
    path: ":sectorId()",
    meta: _91sectorId_93oZzOWsL8HcMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors/[sectorId].vue")
  },
  {
    name: "stocks-portfolios-sectors",
    path: "",
    meta: indexvuxyYCf9xrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors/index.vue")
  }
]
  },
  {
    name: snp500mPrgrMS26sMeta?.name,
    path: "snp500",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue"),
    children: [
  {
    name: "stocks-portfolios-snp500",
    path: "",
    meta: indexRIwpw4c1lXMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue")
  },
  {
    name: "stocks-portfolios-snp500-sectors",
    path: "sectors",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue")
  }
]
  }
]
  },
  {
    name: "stocks-search",
    path: "search",
    meta: searchT1B2Y8KjdfMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/search.vue")
  },
  {
    name: "stocks-top20",
    path: "top20",
    meta: top20QVDMEgSsSmMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/top20.vue")
  }
]
  },
  {
    name: "subscribe-callback",
    path: "/subscribe/callback",
    meta: callback0Nh93rez4dMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/subscribe/callback.vue")
  },
  {
    name: "termsofuse",
    path: "/termsofuse",
    meta: termsofusel5AzRHu0H7Meta || {},
    component: () => import("/usr/src/apps/base/src/pages/termsofuse.vue")
  },
  {
    name: "trademark-copyright-intellectual-property",
    path: "/trademark-copyright-intellectual-property",
    meta: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verify2fhYk5060fMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/verify.vue")
  }
]