<template>
  <div :class="cn(['relative flex items-center'], { 'md:-mx-s': isNavigationVisible }, classes?.root)">
    <UiTransition name="fade" mode="out-in">
      <slot v-if="!isPending" :key="navigationClasses?.prevEl" />

      <!-- // Skeleton loader -->
      <div v-else :class="cn('md:mx-s flex w-full flex-row gap-5 overflow-hidden', classes?.skeleton)">
        <slot v-for="index in skeletonsRepeat" name="skeleton" :key="index" />
      </div>
    </UiTransition>

    <UiButton
      v-show="isNavigationVisible"
      :class="
        cn([
          'button-fade-prev absolute -start-3 h-full w-10 px-0 transition-opacity disabled:bg-transparent disabled:opacity-0 md:hidden',
          navigationClasses?.prevEl,
          classes?.prevEl,
        ])
      "
      variant="link"
    >
      <IconChevronUp class="rotate-90 transition-opacity ltr:-rotate-90" filled :size="18" />
    </UiButton>

    <UiButton
      v-show="isNavigationVisible"
      :class="
        cn([
          'button-fade-next absolute -end-5 h-full w-10 px-0 transition-opacity disabled:bg-transparent disabled:opacity-0 md:hidden',
          navigationClasses?.nextEl,
          classes?.nextEl,
        ])
      "
      variant="link"
    >
      <IconChevronUp class="-rotate-90 transition-opacity ltr:rotate-90" filled :size="18" />
    </UiButton>
  </div>
</template>

<script setup lang="ts">
import { LucideChevronLeft } from "lucide-vue-next"

interface DepotPartialsSwiperNavigationHandlesClasses {
  root?: ClassValue
  prevEl?: ClassValue
  nextEl?: ClassValue
  skeleton?: ClassValue
}

interface DepotPartialsSwiperNavigationHandlesProps {
  isPending?: boolean
  isError?: boolean
  skeletonsRepeat?: number
  hideNavigation?: boolean
  navigationClasses?: {
    prevEl: string
    nextEl: string
  }
  classes?: DepotPartialsSwiperNavigationHandlesClasses
}
// DepotPartialsSwiperNavigationHandles.vue
const props = withDefaults(defineProps<DepotPartialsSwiperNavigationHandlesProps>(), {
  skeletonsRepeat: 3,
})

const { isMobile } = useDisplay()
const isMounted = useMounted()

const isNavigationVisible = computed(
  () => !props.hideNavigation && isMounted && (!isMobile || !props.isError)
)
</script>

<style lang="scss">
.button-fade-next,
.button-fade-prev {
  z-index: 1;
  &:not(:hover) svg {
    opacity: 0.5;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: -2px;
    bottom: -2px;
    pointer-events: none;
    z-index: -5;
  }

  &.button-fade-prev {
    &::after {
      width: 100px;
      inset-inline-start: 0;
    }
  }

  &.button-fade-next {
    &::before {
      width: 100px;
      inset-inline-end: 0;
    }
  }

  &:dir(rtl) {
    &.button-fade-next {
      &::before {
        background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 70%);
      }
    }

    &.button-fade-prev {
      &::after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 70%);
      }
    }
  }

  &:dir(ltr) {
    &.button-fade-next {
      &::before {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
      }
    }
    &.button-fade-prev {
      &::after {
        background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
      }
    }
  }
}
</style>
