import { PopupMessage, PopupOpenResult } from "../../../../packages/ui/types/popup"

export const usePopup = () => {
  const messages = useState<PopupMessage[]>("popup:messages", () => [])

  const open = (alert: PopupMessage): PopupOpenResult => {
    const id = alert.id ?? genUID()
    const fixedMessage: PopupMessage = { ...alert, id, destroy: () => destroyMessage(id).value }

    if (typeof alert.action === "function") {
      fixedMessage.action = { action: alert.action }
    }

    if (typeof alert.cancel === "function") {
      fixedMessage.cancel = { action: alert.cancel }
    }

    messages.value.push(fixedMessage)

    return { id: fixedMessage.id!, destroy: fixedMessage.destroy! } // Useful to prevent the next popup from disappearing
  }

  const destroyMessage = reactify((id: string | undefined) => {
    if (!id) return
    messages.value = messages.value.filter((message) => message.id !== id)
  })

  const close = () => messages.value.pop()

  const clear = () => (messages.value = [])

  return {
    /** List of all the popups */
    messages,
    /** Opens a new popup */
    open,
    /** Will delete the last popup shown */
    close,
    /** Will delete all the popups */
    clear,
  }
}
