export default defineNuxtPlugin({
  name: "local-storage",
  parallel: true,
  setup() {
    const user = useLocalStorage<User | null>("user", null, {
      serializer: { read: JSON.parse, write: JSON.stringify },
      listenToStorageChanges: false,
      shallow: true,
      deep: true,
    })

    return {
      provide: {
        ls: {
          user,
        },
      },
    }
  },
})
