import type { LocationQueryValue, RouteLocation, RouteLocationNormalized } from "#vue-router"
import { isDevelopment } from "std-env"
import type { RouteRecordInfo } from "vue-router"
import { RouteNamedMap } from "vue-router/auto-routes"

type RouteName = string

type FilterPrefixedPaths<T extends RouteName> = T extends `/en/${string}` | `/he/${string}` ? never : T
type RemoveLanguageSuffix<T extends string> = T extends `${infer Base}___he` | `${infer Base}___en`
  ? Base
  : T extends `/he${infer Base}` | `/en${infer Base}`
    ? Base
    : T

type CleanRouteNames<T extends string> = T extends any ? RemoveLanguageSuffix<FilterPrefixedPaths<T>> : never

type ExtractRoutePaths<T> = {
  [K in keyof T]: T[K] extends RouteRecordInfo<any, infer P, any, any> ? P : never
}[keyof T]

export type RoutesNamesList = CleanRouteNames<RouteLocation["name"]>
export type RoutesPathsList = RemoveLanguageSuffix<ExtractRoutePaths<RouteNamedMap>>

export type RouteWithSuffix<T extends RoutesNamesList> = `${T}${"___he" | "___en" | ""}`
export type TypedRouteProps = Omit<Partial<RouteLocation>, "name"> & { name?: RoutesNamesList }

export function getSuffixedRouteNames<T extends RoutesNamesList>(routeName: T): RouteWithSuffix<T>[] {
  const { $i18n } = useNuxtApp()

  const names = $i18n.localeCodes.value.map((localeCode) => `${routeName}___${localeCode}`)

  return names.concat(routeName) as any
}

export function getRouteNameWithLangSuffix<T extends RoutesNamesList>(
  routeName: T,
  locale?: string
): T | RouteWithSuffix<T> {
  const { $i18n } = useNuxtApp()

  if ($i18n.strategy !== "prefix") return routeName
  else if (routeName?.includes("___")) return routeName

  return `${routeName}___${locale || $i18n.locale.value}` as RouteWithSuffix<T>
}

export function isRouteName(routeName: RoutesNamesList, currRouteName: any) {
  try {
    return getSuffixedRouteNames(routeName).includes(currRouteName)
  } catch (e) {
    isDevelopment && console.error('Error in "isRouteName" function:', e)
    return false
  }
}

export function stripRouteNameSuffix(currRouteName: any): RoutesNamesList {
  const { $i18n } = useNuxtApp()

  const localeNamesSuffixes = new RegExp(
    `${$i18n.localeCodes.value.map((localeCode) => `___${localeCode}`).join("|")}`
  )

  return currRouteName?.replace(localeNamesSuffixes, "")
}

export function stripRoutePathPrefix(currRoutePath: Nullable<string>) {
  if (!currRoutePath) return undefined
  const { $i18n } = useNuxtApp()

  const localePathsPrefixes = new RegExp(
    `${$i18n.localeCodes.value.map((localeCode) => `/${localeCode}`).join("|")}`
  )

  return currRoutePath?.replace(localePathsPrefixes, "")
}

export function routerResolve(location: TypedRouteProps) {
  // const { $router } = useNuxtApp()

  const router = useRouter()
  return router.resolve({
    ...(location as RouteLocationNormalized),
    name: getRouteNameWithLangSuffix(location?.name!) as any,
  })
}

export function withQueryParams<T extends TypedRouteProps>(location: T) {
  const route = useRouter().currentRoute

  return {
    ...location,
    query: { ...route.value.query, ...location.query },
  }
}

export function makeParentRouteQuery(parentRoute: RoutesNamesList, queryParam: string | null = null) {
  return {
    parentRoute: [parentRoute, queryParam].filter(Boolean).join("_"),
  }
}

export function splitParentRouteQuery(parentRouteQuery: string | LocationQueryValue | LocationQueryValue[]): {
  parentRoute: string
  queryParam: string | null
} {
  if (!parentRouteQuery) return { parentRoute: "", queryParam: "" }

  const [parentRoute, queryParam] = String(parentRouteQuery).split("_")

  return { parentRoute, queryParam: queryParam || null }
}
