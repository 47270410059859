<script setup lang="ts">
import { ConfigProvider } from "radix-vue"

type MetaType = "title" | "description" | "title_template"

const { direction, isRtl } = useRtl()
const i18n = useI18n()
const route = useRoute()
const { t, te } = useI18n()
const i18nHead = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
  identifierAttribute: "id",
})

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])],
})

const getPageMetaObject = computed(() => {
  const nearestMeta = route.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaData)?.meta.metaData as string

  return nearestMeta ?? "meta.homepage"
})

const pageTitle = computed(() => findNearestPageMeta("title"))
const pageDescription = computed(() => findNearestPageMeta("description"))
const pageTitleTemplate = computed(() => findNearestPageMeta("title_template"))

function findNearestPageMeta(metaType: MetaType) {
  if (te(`${getPageMetaObject.value}.${metaType}`)) return t(`${getPageMetaObject.value}.${metaType}`)

  return t(`meta.homepage.${metaType}`)
}

const useIdFunction = () => useId()
</script>

<template>
  <Html :dir="direction">
    <Head>
      <Title>{{ pageTitle }}</Title>

      <LayoutSEO
        :title="pageTitle"
        :locale-code="i18n.localeProperties.value.iso"
        :description="pageDescription"
        :title-template="pageTitleTemplate"
      />
    </Head>

    <Body>
      <ConfigProvider :dir="direction" :use-id="useIdFunction">
        <div id="app-root" :class="[isRtl ? 'app-rtl' : 'app-ltr']">
          <slot />
        </div>
      </ConfigProvider>
    </Body>
  </Html>
</template>
