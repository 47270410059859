<template>
  <footer class="bg-neutral-100">
    <div class="container mx-auto flex flex-col justify-center px-16 py-10 md:px-5 md:py-8">
      <div
        class="tablet:h-auto tablet:gap-16 mb-16 grid grid-cols-5 flex-col md:h-auto md:w-full md:gap-16 lg:flex"
      >
        <div
          class="tablet:justify-between tablet:flex-row flex flex-col justify-between md:flex-col md:gap-16"
        >
          <UiButton v-bind="logoAction" unset-all :id="genClickId('footer', 'logo')">
            <IconFinqLogoFull />
          </UiButton>

          <div class="flex justify-start gap-4">
            <UiLink
              class="aspect-square size-5 duration-150 hover:scale-125"
              v-for="(socialLink, index) in socialLinks"
              :key="index"
              external
              :href="socialLink.href"
              :id="genClickId('footer', 'social', socialLink.title)"
            >
              <UiNuxtIcon class="size-5" :name="socialLink.icon" filled />
            </UiLink>
          </div>
        </div>

        <div
          class="tablet:flex-row tablet:flex-auto tablet:gap-5 col-span-4 flex flex-auto md:flex-col md:gap-y-14"
        >
          <LayoutFooterSiteBasedLinks />
          <LayoutFooterItemsList
            v-for="(section, index) in footerLinks"
            :key="index"
            :title="section.title"
            :items="section.items"
          />
        </div>
      </div>

      <PricingLocalizationHandler>
        <LayoutFooterDisclaimer class="pb-8 text-sm/6 font-medium text-black/50" />
      </PricingLocalizationHandler>

      <div
        class="tablet:pt-8 tablet:justify-between flex items-center justify-between border-t-2 pt-8 md:justify-between"
      >
        <div class="flex flex-row-reverse items-center gap-1 text-sm text-black/50">
          <span class="text-xs">{{ new Date().getFullYear() }} - 2019 ©</span>
          <span class="text-xs">FINQ. All Rights Reserved</span>
        </div>
        <NuxtImg class="h-4 w-16" :src="getImageUrl('icons/seg-logo.png')" />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
export interface FooterItem {
  id: string
  title?: string
  to: string | TypedRouteProps | undefined
}

interface FooterSection {
  id: string
  title?: string
  items: FooterItem[]
}

const route = useRoute()
const { t } = useI18n()
const { tmsafe } = useI18nUtils({ useScope: "local" })
const isIsrael = isFINQIsrael()

const { scrollTo } = useScrollUtils()
const logoAction = computed(() => {
  if (isRouteName("index", route.name)) {
    return { onClick: () => scrollTo(0) }
  }

  return { prefetch: true, to: { name: "index" } as TypedRouteProps }
})

const socialLinks = ref([
  {
    alt: "X",
    href: "https://x.com/FINQ_AI",
    title: "x",
    icon: "socials/x",
  },
  {
    alt: "Youtube",
    href: "https://www.youtube.com/channel/UCmtuNwfh-yV4D5YHH9VuyuA",
    title: "youtube",
    icon: "socials/youtube",
  },
  {
    alt: "Linkedin",
    href: "https://www.linkedin.com/company/finqai/",
    title: "linkedin",
    icon: "socials/linkedin",
  },
  {
    alt: "Instagram",
    href: isIsrael ? "https://www.instagram.com/finq_israel/" : "https://www.instagram.com/finq_ai/",
    title: "instagram",
    icon: "socials/instagram",
  },
  {
    icon: "socials/facebook",
    alt: "FaceBook",
    href: isIsrael ? "https://www.facebook.com/finqisrael" : "https://www.facebook.com/finqai",
    title: "facebook",
  },
])

const footerLinks = computed(() => {
  let linksConfig: FooterSection[] = []
  if (isIsrael) {
    linksConfig = [
      {
        id: "explore",
        items: [
          { id: "blog", to: "https://blog.finqai.com/" },
          { id: "partners", to: { name: "partners" } },
          { id: "newsroom_il", to: "https://press.finqai.co.il" },
          { id: "newsroom_global", to: "https://press.finqai.com" },
        ],
      },
      {
        id: "company",
        items: [
          { id: "about", to: { name: "about" } },
          { id: "contact", to: { name: "contact" } },
          { id: "pricing", to: { name: "pricing" } },
        ],
      },
      {
        id: "legal",
        items: [
          { id: "security", to: { name: "datasecurity" } },
          { id: "privacy", to: { name: "privacy" } },
          { id: "terms", to: { name: "termsofuse" } },
        ],
      },
    ]
  } else {
    linksConfig = [
      {
        id: "explore",
        items: [
          { id: "blog", to: "https://blog.finqai.com/" },
          { id: "partners", to: { name: "partners" } },
          { id: "newsroom", to: "https://press.finqai.com" },
        ],
      },
      {
        id: "company",
        items: [
          { id: "about", to: { name: "about" } },
          { id: "contact", to: { name: "contact" } },
          { id: "pricing", to: { name: "pricing" } },
        ],
      },
      {
        id: "legal",
        items: [
          { id: "security", to: { name: "datasecurity" } },
          { id: "privacy", to: { name: "privacy" } },
          { id: "terms", to: { name: "termsofuse" } },
        ],
      },
    ]
  }

  addTitles(linksConfig)
  return linksConfig
})

const addTitles = (config: FooterSection[]) => {
  config.forEach((section) => {
    section.title = tmsafe(`footer_links.${section.id}.title`)
    section.items.forEach((item) => {
      item.title = tmsafe(`footer_links.${section.id}.items.${item.id}`)
    })
  })
}
</script>

<i18n lang="json">
{
  "en": {
    "footer_links": {
      "explore": {
        "title": "Explore",
        "items": {
          "blog": "Blog",
          "partners": "Partners",
          "newsroom": "Newsroom",
          "newsroom_global": "Newsroom Global",
          "newsroom_il": "Newsroom Israel"
        }
      },
      "company": {
        "title": "Company",
        "items": {
          "about": "About",
          "contact": "Contact",
          "pricing": "Pricing"
        }
      },
      "legal": {
        "title": "Legal and regulatory",
        "items": {
          "security": "Security",
          "privacy": "Privacy",
          "terms": "Terms"
        }
      }
    }
  },
  "he": {
    "footer_links": {
      "explore": {
        "title": "Explore",
        "items": {
          "blog": "בלוג",
          "partners": "FINQ לעסק שלך",
          "newsroom": "Newsroom",
          "newsroom_global": "Newsroom Global",
          "newsroom_il": "Newsroom Israel"
        }
      },
      "company": {
        "title": "Company",
        "items": {
          "about": "אודות",
          "contact": "יצירת קשר",
          "pricing": "תמחור"
        }
      },
      "legal": {
        "title": "Legal and regulatory",
        "items": {
          "security": "אבטחת מידע",
          "privacy": "מדיניות פרטיות",
          "terms": "תנאי שימוש"
        }
      }
    }
  }
}
</i18n>
