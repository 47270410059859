<template>
  <NuxtLazyHydrate :when-idle="4000">
    <RendererWithProps v-if="tag === 'template'" />
    <component
      v-else
      v-bind="$attrs"
      :class="cn('prose text-primary prose-a:break-all prose-a:text-inherit max-w-full whitespace-pre-line', $attrs?.class!)"
      :is="tag"
    >
      <RendererWithProps />
    </component>
  </NuxtLazyHydrate>
</template>

<script setup lang="tsx">
import { memoize } from "lodash-es"

const { locale } = useI18n()
const rerenderKey = ref(0)

const props = withDefaults(
  defineProps<{
    tag?: string
    content: string
    components?: Record<string, any>
  }>(),
  { tag: "div", content: "" }
)

watch(
  () => [props.content, locale.value],
  () => rerenderKey.value++
)

/**
 * Custom components renderer ---------------------------------------------
 * Allows to use custom components in html string content
 */
const components = {
  // Will replace all <a> tags with <ui-link> tags
  a: resolveComponent("UiLink"),
  b: <strong />,
  ...props.components,
}

/** Prefixes all components with a given prefix */
const prefixComponents = memoize((comps: Record<string, any>, prefix = "ui") => {
  try {
    return Object.fromEntries(
      Object.entries(comps).map(([key, value]) => [`${prefix}-${key.toLowerCase()}`, value])
    )
  } catch (e) {
    console.error("[HTMLRenderer] Error while prefixing components", e)

    return comps
  }
})

/** Prefixes found html tags with a given prefix */
const prefixContentComponents = memoize((content: string, comps: Record<string, any>, prefix = "ui") => {
  try {
    // replaces all html tags with prefixed tags but keeps the attributes
    return Object.keys(comps).reduce((acc, key) => {
      const lowKey = key.toLowerCase()

      return acc
        .replace(new RegExp(`<${lowKey}(\\b[^>]*)`, "g"), `<${prefix}-${lowKey}$1`)
        .replace(new RegExp(`<\/${lowKey}>`, "g"), `</${prefix}-${lowKey}>`)
    }, content)
  } catch (e) {
    console.error("[HTMLRenderer] Error while prefixing content components", e)

    return content
  }
})

const Renderer = defineComponent({
  name: "ComponentRenderer",
  props: { content: { type: String, required: true } },
  components: prefixComponents(components),
  setup: async (props) => {
    // @ts-ignore
    const compile = await import("vue/dist/vue.esm-bundler.js").then((m) => m.compile)

    return compile(prefixContentComponents(props.content, components))
  },
  inheritAttrs: false,
})

// eslint-disable-next-line
const RendererWithProps = computed(() => <Renderer content={props.content} key={rerenderKey.value} />)
</script>
