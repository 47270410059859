export interface SEOMeta extends Record<string, string | SEOMeta> {
  title: string
  description: string
  titleTemplate: string
}

export interface SEOParams {
  title?: Nullable<string>
  description?: string
  titleTemplate: string
  ogImage: string
  url: string
  shortName: string
}

// If the text is longer than 60 characters, it should be truncated and add an ellipsis
function clampToMax60(text: Nullable<string>, maxLength = 60) {
  return !text ? "" : text.length > maxLength ? text.slice(0, maxLength) + "…" : text
}

export function useTitleTemplate(template: MaybeRefOrGetter<Nullable<string>>) {
  template = toRef(template)
  return computed(() => (template.value === "" ? "%s" : "%s | " + template.value))
}

export function useSeo(data: MaybeRefOrGetter<NullableAll<Partial<SEOParams>> | undefined>) {
  const dataAsRef = toRef(data)

  const {
    tmsafe,
    i18n: { localeProperties },
  } = useI18nUtils({ useScope: "global" })

  const route = useRoute()
  const config = useRuntimeConfig()
  const siteUrl = config.public.NUXT_PUBLIC_SITE_URL
  const defaultMeta = computed(() => tmsafe<SEOMeta>("meta.homepage"))

  const mappedUrl = computed(() => {
    if (seo.value?.url) return seo.value.url
    if (route.path === "/") return siteUrl

    return siteUrl + route.path
  })

  const seo = computed(() => {
    const seoWithDefaults = {
      title: defaultMeta.value.title,
      titleTemplate: defaultMeta.value.titleTemplate,
      shortName: "FINQ",
      ogImage: "/og-image.png",
      ...jsonClean(dataAsRef.value),
    } as Required<SEOParams>

    return {
      ...seoWithDefaults,
      title: clampToMax60(seoWithDefaults.title),
    }
  })

  const titleTemplate = useTitleTemplate(seo.value.titleTemplate)

  useSeoMeta({
    ...(seo.value?.title ? { title: computed(() => removeWhitespace(seo.value.title)) } : undefined),
    ...(seo.value?.description
      ? { description: computed(() => removeWhitespace(seo.value.description)) }
      : undefined),
    ...(seo.value?.titleTemplate && { titleTemplate }),
    // titleTemplate,

    ...jsonClean({
      ogType: "website" as const,
      ogUrl: mappedUrl.value,
      ogLocale: localeProperties.value.iso,
      ogSiteName: seo.value?.shortName,
      ogImage: seo.value?.ogImage,
      ogImageAlt: seo.value?.shortName,
      ogImageHeight: 314,
      ogImageWidth: 600,

      twitterCard: "summary_large_image" as const,
      twitterImage: seo.value?.ogImage,
    }),
  })

  /** Schema Org / JSON-LD */
  useSchemaOrg([
    defineWebPage({
      name: seo.value?.title,
      url: mappedUrl.value,
    }),
  ])

  return { seo }
}
