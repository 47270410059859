import { useQuery } from "@tanstack/vue-query"

interface UseMeQueryWrapperProps {
  enabled?: boolean | (() => boolean)
}

export function useMe({ enabled = undefined }: UseMeQueryWrapperProps = {}) {
  const { isAuthenticated, saveUser, deleteUser } = useUser()

  return useQuery({
    enabled: enabled ?? isAuthenticated.value,
    queryKey: ["validateAndRefreshUser"],
    queryFn: async () => {
      try {
        const user = await UserService.getMe()

        saveUser(user)

        return user as Promise<User>
      } catch (err) {
        deleteUser()
        throw err
      }
    },
    staleTime: Infinity,
  })
}
