<template>
  <component
    v-bind="{ ...anchorOrButtonProps.props, ...gtmDatasets, ...$attrs }"
    :id="id"
    :is="anchorOrButtonProps.tag"
    :class="classes"
    :disabled="disabled || loading"
    :to="props.to"
    :href="props.href"
    :tabindex="props.is || props.to ? undefined : disabled ? '-1' : '0'"
    :aria-disabled="disabled"
    :replace="replace"
    :prefetch="prefetch"
    ui-button
    @click="$emit('click', $event)"
  >
    <template v-if="loading">
      <UiSpinner size="s" :key="loading ? 'loading' : 'not-loading'" />
    </template>
    <slot v-else />
  </component>
</template>

<script lang="ts">
import type { ButtonHTMLAttributes } from "vue"

export interface UiButtonProps
  extends /* @vue-ignore */ Omit<ButtonHTMLAttributes, "class" | "color" | "onClick" | "id" | "disabled"> {
  id?: string
  variant?: VariantProps<typeof buttonVariants>["variant"]
  size?: VariantProps<typeof buttonVariants>["size"]
  color?: VariantProps<typeof buttonVariants>["color"]
  rounded?: VariantProps<typeof buttonVariants>["rounded"]
  class?: ClassValue
  circle?: boolean
  unsetAll?: boolean
  disabled?: boolean
  loading?: boolean
  replace?: boolean
  prefetch?: boolean
  withoutLocalePath?: boolean
  to?: TypedRouteProps | string
  href?: string
  is?: string
  dataAction?: "signup" | "subscribe" | "waitlist" | "navigate"
}

export const buttonVariants = cva(
  "border border-transparent inline-flex items-center justify-center rounded-sm text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:bg-muted disabled:text-black/50 ring-offset-background",
  {
    variants: {
      variant: {
        default: "bg-primary text-white hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-border hover:bg-accent hover:text-accent-foreground",
        "list-item":
          "text-black/50 text-base font-normal hover:font-medium justify-start gap-xxs h-auto text-start min-h-8.5 py-2 rounded-none no-underline w-full [&.active]:font-medium [&.active]:text-primary hover:text-primary",
        alternative: "bg-alternative text-alternative-foreground hover:bg-neutral-200",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "underline-offset-4 hover:underline text-primary font-semibold disabled:bg-transparent",
        pill: "bg-primary text-primary-foreground text-lg rounded-full",
        white: "bg-white text-black/50 font-light hover:bg-white",
        "light-gray": "bg-muted text-black/50 font-light hover:bg-muted hover:text-black",
        icon: "aspect-square text-primary focus-visible:ring-offset-4 hover:text-black/50",
      },

      size: {
        default: "h-8.5 py-2 px-xs",
        xxs: "h-xs px-xs rounded-md",
        xs: "h-7 px-1 rounded-md",
        sm: "h-9 px-xs rounded-md",
        md: "h-m px-xs rounded-md",
        lg: "h-11 px-8 rounded-md",
        flat: "",
      },

      color: {
        primary: "",
        destructive: "",
        accent: "",
        alternative: "",
        success: "",
        white: "bg-white",
      },

      link: {
        default: "",
        true: "[&.active]:underline [&.active]:text-primary",
      },

      circle: {
        default: "",
        true: "aspect-square rounded-full p-1",
      },

      rounded: {
        default: "rounded-full",
        sm: "rounded-sm",
        full: "rounded-full",
        none: "rounded-none",
      },
    },

    compoundVariants: [
      {
        variant: "outline",
        color: "primary",
        class: "border-primary hover:bg-primary/90 hover:text-white",
      },
      {
        variant: "ghost",
        color: "destructive",
        class: "hover:bg-destructive-foreground hover:text-destructive",
      },
      {
        variant: "icon",
        size: "default",
        class: "p-0 size-5 rounded-xs",
      },
    ],

    defaultVariants: {
      variant: "default",
      size: "default",
      link: "default",
      circle: "default",
      rounded: "default",
    },
  }
)
</script>

<script setup lang="ts">
import { type VariantProps, cva } from "class-variance-authority"

const emit = defineEmits(["click"])
const props = withDefaults(defineProps<UiButtonProps>(), { prefetch: false, replace: undefined })

const anchorOrButtonProps = computed(() => {
  if (props.to || props.href) {
    return {
      tag: resolveComponent("UiLink"),
      props: {
        activeClass: "active",
        exactActiveClass: "active",
        withoutLocalePath: props.withoutLocalePath,
        "data-action": "navigate",
      },
    }
  } else if (props.is) {
    return { tag: props.is, props: undefined }
  }

  return { tag: "button", props: { type: "button" } }
})

const gtmDatasets = computed(() => {
  return jsonClean({
    "data-type": "cta",
    "data-position": genDataPosition(),
    "data-action": props.dataAction,
  })
})

const classes = computed(() =>
  cn(
    props.unsetAll
      ? props.class
      : buttonVariants({
          variant: props.variant,
          size: props.size,
          color: props.color,
          class: props.class as string,
          link: !!props.to && props.variant === "link",
          circle: props.circle,
          rounded: props.rounded,
        })
  )
)
</script>
