<template>
  <DefineTemplate>
    <li v-for="link in productLinks" :key="link.id">
      <UiButton
        :class="
          cn(
            'text-primary h-fit w-fit whitespace-pre px-0 py-0 leading-none hover:font-normal hover:text-black/50 md:px-4 [&.active]:font-semibold',
            { 'text-black/30 hover:text-black/30': link.upcoming }
          )
        "
        :to="{ name: link.screenPath as RoutesNamesList }"
        variant="list-item"
        :id="genClickId('nav', 'link', link.screenPath)"
        :disabled="link.upcoming"
        @click="emit('menu:close')"
      >
        {{ link.label }}
        <div v-if="link.upcoming">
          <UiBadge :classes="{ root: cn('rounded-full bg-neutral-200 px-1.5 text-black/30') }"
            ><span class="text-xxs leading-4">Upcoming</span></UiBadge
          >
        </div>
      </UiButton>
    </li>
  </DefineTemplate>

  <template v-if="bp.isMobile.value">
    <ReuseTemplate />
  </template>

  <template v-else>
    <ul class="flex gap-10">
      <ReuseTemplate />
    </ul>
  </template>
</template>

<script setup lang="ts">
import { userMenuActiveClasses } from "@finq/app-base/components/layout/UserMenu/UserMenuListItems.vue"

type ProductNavigationMenuLink = {
  id: keyof typeof PricingProductEnum
  screenPath: string
  label: string
  upcoming?: boolean
}

const bp = useDisplay()
const { tmsafe } = useI18nUtils({ useScope: "local" })
const [DefineTemplate, ReuseTemplate] = createReusableTemplate()

const emit = defineEmits<{ (e: "menu:close"): void }>()

const productLinks = computed(() => tmsafe<ProductNavigationMenuLink[]>("products.links"))
</script>

<i18n lang="json">
{
  "en": {
    "products": {
      "links": [
        { "id": "Stocks", "label": "Stocks", "screenPath": "stocks-depot" },
        { "id": "Funds", "label": "Funds", "upcoming": true, "screenPath": "funds" }
      ]
    }
  },
  "he": {
    "products": {
      "links": [
        { "id": "Stocks", "label": "מניות", "screenPath": "stocks-depot" },
        { "id": "Funds", "label": "Funds", "upcoming": true, "screenPath": "funds" }
      ]
    }
  }
}
</i18n>
