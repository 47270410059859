import { type InferOutput, boolean, number, object, optional, picklist, string } from "valibot"

export type Language = (typeof LanguageEnum)[keyof typeof LanguageEnum]

export interface Pagination {
  total: number
  index: number
  size: number
}

export const paginationSchema = object({})

export interface PaginationResponse<T> {
  data: T
  paging: Pagination
  groups?: string[]
  additional?: Record<string, any>
}

export const paginationParamsSchema = object({
  index: optional(number()),
  size: optional(number()),
})
export type PaginationParams = InferOutput<typeof paginationParamsSchema>

export const sortByParamsSchema = object({
  sortBy: optional(string()),
  order: optional(picklist(["ASC", "DESC"])),
})
export type SortByParams = InferOutput<typeof sortByParamsSchema>

export const GeneralParameters = object({
  currency: string(),
  localTrade: boolean(),
  alertsMonthlyPrice: number(),
  alertsYearlyPrice: number(),
  fullManagementPrice: number(),
  assetRealtimeInterval: number(),
  assetRealtimeUpdateTime: number(),
  fundBatchRunDate: number(),
  stockRankingDate: number(),
  stockRankingDateTime: number(),
  stockRankingYieldDate: number(),
  stockRankingYieldDateTime: number(),
  pensionHoldingsQuarter: optional(string()),
})

export const durationSchema = object({
  frequency: optional(picklist(["day", "week", "month", "year"])),
  duration: optional(number()),
})
export type Duration = InferOutput<typeof durationSchema>

export type GeneralParameters = InferOutput<typeof GeneralParameters>

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[] ? ElementType : never

export type Nullable<T> = T | null | undefined

export type NullableAll<T> = {
  [P in keyof T]: Nullable<T[P]>
}
