<template>
  <div v-auto-animate>
    <div class="flex gap-2 md:hidden" v-if="!isAuthenticated">
      <UiButton class="font-medium" @click="login.open" :key="isHomePage + 'nav_login'" variant="outline">
        {{ $t("authentication.buttons.log_in") }}
      </UiButton>

      <UiButton @click="login.open({}, 'signup')">{{ $t("authentication.buttons.signup") }} </UiButton>
    </div>

    <LayoutUserMenu :class="cn({ 'min-md:hidden': !isAuthenticated })" :links />
  </div>
</template>

<script setup lang="ts">
import type { LayoutUserMenu } from "#components"

import { HeaderMenuLinks } from "./Navbar.vue"

defineProps<{ links?: HeaderMenuLinks }>()
const route = useRoute()
const login = useLogin()
const { isAuthenticated } = useUser()
const isHomePage = computed(() => isRouteName("index", route.name))
</script>
