<template>
  <LayoutNavbarContainer
    :elevated-navbar="elevatedNavbar"
    :navbar-variant="route.meta.layoutProps?.navbarVariant"
    :classes="{
      container: 'justify-between',
    }"
  >
    <div class="flex items-center md:rtl:flex-row-reverse">
      <div class="flex items-center md:rtl:flex-row-reverse">
        <UiButton
          class="h-fit p-0 transition-all duration-300"
          v-bind="logoAction"
          variant="link"
          :id="genClickId('nav', 'logo')"
        >
          <div class="min-md:w-14" v-if="isAuthorizedAndMounted">
            <IconFinqLogo :size="24" />
          </div>
          <IconFinqLogoFull v-else />
        </UiButton>
      </div>

      <div
        v-if="!hideLinks"
        :class="
          cn('flex items-center gap-10 transition-all duration-200 md:hidden', {
            'ms-10': !isAuthorizedAndMounted,
          })
        "
      >
        <LayoutNavbarProductNavigation />

        <UiTabs
          :list="commonLinks"
          :classes="{
            root: 'gap-10',
            tab: cn([
              'text-primary md:px-xs h-fit w-fit whitespace-pre p-0 leading-none hover:font-normal hover:text-black/50 [&.active]:font-semibold',
            ]),
          }"
          :variants="{ default: 'list-item', active: 'list-item' }"
          :attributes="
            ({ item, selected }) => ({
              tab: {
                id: genClickId('nav', 'link', item.to?.name! ?? item.to),
                class: cn({ active: selected }),
              },
            })
          "
        />
      </div>
    </div>

    <div class="flex items-center" v-if="!hideUserActions">
      <div id="navbar-teleport" />

      <LayoutLanguageSwitcher class="me-s h-9 w-9 md:hidden" show-text />

      <LayoutMenuOrAuthButtons :links="menuLinks" />
    </div>

    <UiButton
      class="aspect-square min-w-36 rounded-full"
      v-if="hideUserActions"
      variant="outline"
      @click="$router.go(-1)"
    >
      <IconCloseIcon aria-hidden="true" :size="26" />
    </UiButton>
  </LayoutNavbarContainer>
</template>

<script setup lang="ts">
import { isEmpty } from "lodash-es"

import { TypedRouteProps } from "@finq/app-base/composables/routes-utils"

import { PricingPlanTypes } from "@finq/pricing/types"
import { UiTabsOption } from "@finq/ui/components/ui/Tabs.vue"

export type HeaderMenuLink = {
  label: string
  to: TypedRouteProps | string
  href?: string
  isComingSoon?: boolean
  hideForPayedUsers?: boolean
}

export type HeaderMenuLinks = {
  products: UiTabsOption[]
  links?: Partial<UiTabsOption>[]
}

interface NavTabsOption extends UiTabsOption {
  hideForPayedUsers?: boolean
}

defineProps<{ backButton?: boolean; hideLinks?: boolean; hideUserActions?: boolean; closeButton?: boolean }>()
const bp = useDisplay()
const { isAuthenticated, user } = useUser()
const { tmsafe } = useI18nUtils()
const route = useRoute()
const { t } = useI18n()
const { scrollPos, scrollTo } = useScrollUtils()

const userPayed = computed(() => {
  const userPayedSubscriptions =
    user.value?.subscriptions?.filter(
      (subscription) => subscription?.productPlanPricingType !== PricingPlanTypes.PlanPeriodType.FREE
    ) ?? []
  return userPayedSubscriptions.length > 0
})

const logoAction = computed(() => {
  if (isRouteName("index", route.name)) {
    return { onClick: () => scrollTo(0) }
  }

  return { prefetch: true, to: { name: "index" } as TypedRouteProps }
})

const elevatedNavbar = computed(() => {
  const metaElevated = route.meta?.layoutProps?.elevatedNavbar

  if (isNullOrUndefined(metaElevated)) return true

  return metaElevated || scrollPos.value > 10
})

// -------------------------------------------------------- //

const commonLinks = computed((): UiTabsOption[] => {
  const links = tmsafe("common.nav_bar_section.links") as HeaderMenuLink[]

  return links
    .map((link) => {
      const { to, isComingSoon, label, hideForPayedUsers } = link

      const base = { label, disabled: isComingSoon, hideForPayedUsers } as NavTabsOption

      if (isComingSoon) return base

      if (to && !isEmpty(to)) base.to = to as TypedRouteProps

      return base
    })
    .filter((link) => !(userPayed.value && link.hideForPayedUsers))
})

const userLinks = computed((): Partial<UiTabsOption>[] => {
  return [{ label: t("authentication.buttons.profile"), to: { name: "profile-info" } }]
})

const menuLinks = computed((): HeaderMenuLinks => {
  let base = {} as HeaderMenuLinks

  if (bp.isMobile.value) base.products = commonLinks.value
  if (isAuthenticated.value) base.links = userLinks.value

  return base
})

const isMounted = useMounted()
const isAuthorizedAndMounted = computed(() => isAuthenticated.value && isMounted.value)
</script>
