<template>
  <!-- SEO: Leave Empty -->
</template>

<script setup lang="ts">
import { type SEOParams, useSeo } from "@finq/app-base/composables/useSeo"

const route = useRoute()
const config = useRuntimeConfig()
const user = useUser()
const props = withDefaults(defineProps<Partial<SEOParams>>(), {
  title: "FINQ",
  shortName: "FINQ",
})

const { localeProperties } = useI18n({ useScope: "global" })

/** Use title template */
const titleTemplate = useTitleTemplate(props?.titleTemplate)
useSeoMeta({ titleTemplate })

/** SEO hook */
useSeo(props)

/** Schema Org / JSON-LD */
useSchemaOrg([
  defineOrganization({
    name: props.shortName,
    url: config.NUXT_PUBLIC_SITE_URL,
    logo: props.ogImage,
    image: props.ogImage,
    sameAs: [],
  }),
  defineWebSite({
    name: props.shortName,
    url: config.NUXT_PUBLIC_SITE_URL,
    image: props.ogImage,
    inLanguage: localeProperties.value.iso,
  }),
])

/**
 * Hotjar attributes
 */
useSetHotJarAttributes(
  computed(() => ({
    "FINQ User ID": user.user.value?.id,
    "User Waiting list": user.user.value?.waitingListNotifications,
    "User Paid": user.user.value?.allowedProducts?.length! > 0,
    "Is in subscription page": ["pricing", "pricing-productId"].includes(stripRouteNameSuffix(route.name)),
    "Paid and returned from Stripe":
      stripRouteNameSuffix(route.name) === "subscribe-callback" && !!route.query?.checkoutSuccess,
  }))
)
</script>
