import qs from "qs"

export type ParsedQuery = Record<string, boolean | string | number | (string | number)[] | undefined | null>

function validateValue(val: string | number): string | number {
  return typeof val === "string" && isValidNumber(val.trim()) && val.trim() !== "" ? parseFloat(val) : val
}

function fixParsedQuery(parsed: ParsedQuery | undefined): ParsedQuery {
  if (!parsed) return {}

  return Object.keys(parsed).reduce((acc: ParsedQuery, key: string) => {
    let value = parsed[key]

    if (Array.isArray(value)) {
      // Filter out empty strings and map over the array to convert strings to numbers where applicable.
      value = (value as string[]).filter((item) => item.trim() !== "") // Remove empty strings

      acc[key] = value.map(validateValue)
    } else {
      // Convert single value if it is a valid number string and not empty.
      acc[key] = validateValue(value as string | number)
    }

    return acc
  }, {} as ParsedQuery)
}

export function stringifyQuery(query: ParsedQuery | undefined) {
  if (!query) return ""

  return qs.stringify(query, {
    arrayFormat: "comma",
    encode: false,
    allowEmptyArrays: false,
    skipNulls: true,
  })
}

export function parseQuery(query: string) {
  const parsed = qs.parse(query, { comma: true })
  return fixParsedQuery(parsed as ParsedQuery) as Record<string, string>
}
