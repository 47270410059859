import { StockIndices, StockIndicesSymbol } from "../types/stocks-depot"

export const currentStockIndex = ref<StockIndicesSymbol>(StockIndices.SYMBOL.SNP500)
export function useStocksDepot() {
  const stocksIndicsList = reactive([
    {
      label: StockIndices.LABEL.SNP500,
      value: StockIndices.SYMBOL.SNP500,
    },
    {
      label: StockIndices.LABEL.NASDAQ100,
      value: StockIndices.SYMBOL.NASDAQ100,
      disabled: true,
    },
    {
      label: StockIndices.LABEL.DOW30,
      value: StockIndices.SYMBOL.DOW30,
      disabled: true,
    },
  ])

  return { currentStockIndex, stocksIndicsList }
}
