<script setup lang="ts">
import { type HTMLAttributes } from "vue"

import { PopoverRoot, useForwardPropsEmits } from "radix-vue"
import type { PopoverRootEmits, PopoverRootProps } from "radix-vue"

const props = defineProps<PopoverRootProps & Partial<{ class: HTMLAttributes["class"] }>>()
const emits = defineEmits<PopoverRootEmits>()

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <div>
    <PopoverRoot v-bind="forwarded">
      <slot />
    </PopoverRoot>
  </div>
</template>
