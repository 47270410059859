<template>
  <svg
    class="rounded-full"
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    :viewBox="`0 0 ${size} ${size}`"
  >
    <g :transform="`translate(${size / 2},${size / 2})`">
      <circle :style="`fill: ${bgColor}`" :r="size / 2" />
      <text
        text-anchor="middle"
        dominant-baseline="central"
        :style="{ fill: textColor, fontSize: Number(size * 0.7).toFixed(2) + 'px', fontWeight: 400 }"
      >
        {{ getText }}
      </text>
    </g>
  </svg>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    text: string | undefined
    size?: number
    bgColor?: string
    textColor?: string
    textSize?: number | string
  }>(),
  { size: 30, bgColor: "#000", textColor: "#fff" }
)

const getText = computed(() => {
  const text = props.text?.toUpperCase() || "F"

  return text.length > 1 ? text.substring(0, 1) : text
})
</script>
