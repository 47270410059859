import { AppBreakpoints } from "@finq/app-base/lib/configs"

export function useDisplay() {
  const breakpoints = useBreakpoints(AppBreakpoints)

  return reactifyObject({
    ...breakpoints,
    isMobile: breakpoints.smallerOrEqual("md"),
    isDesktop: breakpoints.greater("md"),
  })
}
