<template>
  <Transition v-bind="{ ...classNames, ...transitionProps }" :css="!disabled" :mode="mode">
    <slot />
  </Transition>
</template>

<script lang="ts">
export interface HTMLExpandElement extends HTMLElement {
  _parent?: (Node & ParentNode & HTMLElement) | null
  _initialStyle?: {
    transition: string
    overflow: string
    height?: string | null
    width?: string | null
  }
}

export type ExpandTransitionProps = BaseTransitionProps<HTMLExpandElement>

export interface UiExpandTransitionProps extends /* @vue-ignore */ ExpandTransitionProps {
  duration?: EnterLeaveStructure<TwTiming<"duration">>
  delay?: EnterLeaveStructure<TwTiming<"delay">>
  disabled?: boolean
  horizontal?: boolean
  expandedParentClass?: string
  hideOnLeave?: boolean
  leaveAbsolute?: boolean
  withOpacity?: boolean
  mode?: "default" | "out-in" | "in-out"
}
</script>

<script setup lang="ts">
import { camelize } from "vue"

import type { BaseTransitionProps, TransitionProps } from "nuxt/dist/app/compat/capi"

//******************************************************** Computed ********************************************************\\

const props = withDefaults(defineProps<UiExpandTransitionProps>(), { expandedParentClass: "" })

const sizeProperty = computed((): "width" | "height" => (props.horizontal ? "width" : "height"))

const offsetProperty = computed(
  () => camelize(`offset-${sizeProperty.value}`) as "offsetHeight" | "offsetWidth"
)

const classNames = computed((): TransitionClasses => {
  const twTransitionProperty = sizeProperty.value === "width" ? "transition-width" : "transition-height"

  return {
    enterActiveClass: cn(
      FinqTransitions.expand.enterActiveClass,
      twTransitionProperty,
      getFromEnterLeaveProp(props.duration!, "enter"),
      getFromEnterLeaveProp(props.delay!, "enter")
    ),
    enterFromClass: cn(FinqTransitions.expand.enterFromClass, { "opacity-0": props.withOpacity }),
    enterToClass: cn(FinqTransitions.expand.enterToClass, { "opacity-100": props.withOpacity }),
    leaveActiveClass: cn(
      FinqTransitions.expand.leaveActiveClass,
      twTransitionProperty,
      getFromEnterLeaveProp(props.duration, "leave"),
      getFromEnterLeaveProp(props.delay, "leave")
    ),
    leaveFromClass: cn(FinqTransitions.expand.leaveFromClass, { "opacity-100": props.withOpacity }),
    leaveToClass: cn(FinqTransitions.expand.leaveToClass, { "opacity-0": props.withOpacity }),
  }
})

const transitionProps = computed(() => {
  return (
    props.disabled
      ? {}
      : {
          onBeforeEnter: onBeforeEnter,
          onEnter: onEnter,
          onAfterEnter: resetStyles,
          onEnterCancelled: resetStyles,
          onLeave: onLeave,
          onAfterLeave: onAfterLeave,
          onLeaveCancelled: onAfterLeave,
        }
  ) as TransitionProps
})

//******************************************************** Methods ********************************************************\\

const onBeforeEnter: ExpandTransitionProps["onBeforeEnter"] = (el) => {
  el._parent = el.parentNode as (Node & ParentNode & HTMLElement) | null
  el._initialStyle = {
    transition: el.style.transition,
    overflow: el.style.overflow,
    [sizeProperty.value]: el.style[sizeProperty.value],
  }
}

const onEnter: ExpandTransitionProps["onEnter"] = (el) => {
  const initialStyle = el._initialStyle!

  el.style.setProperty("transition", "none", "important")
  // Hide overflow to account for collapsed margins in the calculated height
  el.style.overflow = "hidden"
  const offset = `${el[offsetProperty.value]}px`

  el.style[sizeProperty.value] = "0"

  void el.offsetHeight // force reflow

  el.style.transition = initialStyle.transition

  if (props.expandedParentClass && el._parent) {
    el._parent.classList.add(props.expandedParentClass)
  }

  requestAnimationFrame(() => {
    el.style[sizeProperty.value] = offset
  })
}

const resetStyles:
  | ExpandTransitionProps["onAfterEnter"]
  | ExpandTransitionProps["onEnterCancelled"]
  | ExpandTransitionProps["onLeaveCancelled"] = (el) => {
  const size = el._initialStyle![sizeProperty.value]

  el.style.overflow = el._initialStyle!.overflow
  if (size != null) el.style[sizeProperty.value] = size
  delete el._initialStyle
}

const onLeave: ExpandTransitionProps["onLeave"] = (el) => {
  el._initialStyle = {
    transition: "",
    overflow: el.style.overflow,
    [sizeProperty.value]: el.style[sizeProperty.value],
  }

  el.style.overflow = "hidden"
  el.style[sizeProperty.value] = `${el[offsetProperty.value]}px`
  void el.offsetHeight // force reflow

  requestAnimationFrame(() => (el.style[sizeProperty.value] = "0"))
}

const onAfterLeave: ExpandTransitionProps["onAfterLeave"] = (el) => {
  if (props.expandedParentClass && el._parent) {
    el._parent.classList.remove(props.expandedParentClass)
  }

  resetStyles(el)
}

//******************************************************** Helpers ********************************************************\\
</script>
