import SessionTimeout from "../components/auth/SessionTimeout.vue"

const MAX_AFK_TIME = 15 /* idle duration, should be in minutes */

export function useIdleTracking(): void {
  const user = useUser()
  const popup = usePopup()
  const { t } = useI18n()
  const login = useLogin()

  const { idle, reset } = useIdle(MAX_AFK_TIME * 60 * 1000)
  const popupOpened = ref(false)

  watch(idle, async (idleValue) => {
    if (!user.isAuthenticated.value) return

    if (idleValue && !popupOpened.value) {
      popupOpened.value = true

      // Logout the user, and show popup
      await UserService.logout()

      popup.open({
        title: t("system.session_timeout"),
        component: shallowRef(SessionTimeout),
        componentData: {
          onLogin() {
            popup.close()
            login.open({}, "login")
            popupOpened.value = false
            reset()
          },
        },
        classes: { title: "leading-7", body: "text-center min-md:pt-5 md:pt-6 min-h-44 md:min-h-36" },
        close: () => {
          popupOpened.value = false // reset popupOpened value
          reset() // restarts the idle timer. Does not change lastActive value
        },
      })
    }
  })
}
