import { PopupMessage } from "@finq/ui/types/popup"

/**
 * shared `AuthManager` css variants accross related components
 */
export const authManagerVariants = {
  variant: { horizontal: "flex-row gap-xxs [&>button]:flex-1", default: "" },
}

export const AuthUserEmailStateMap = {
  signup: "user_email_signup_confirmation",
  login_verification: "user_email_login_verification",
  reset: "user_email_reset",
}

export interface AuthApiError {
  code: 400 | 401 | 403 | 404 | 406 | 409 | 429
  message: string
  field: string
}

const apiErrorState = ref<AuthApiError | null>(null)

export const useAuthApiError = () => {
  const clear = () => {
    apiErrorState.value = {} as AuthApiError
  }

  const isForgotPassError = computed(() => apiErrorState.value?.code === 403)

  return { error: apiErrorState, clear, isForgotPassError }
}

const formState = reactive({
  email: undefined as unknown as string,
  password: undefined as unknown as string,
  firstName: undefined as unknown as string,
  lastName: undefined as unknown as string,
})

export const useAuthManager = () => {
  const snackbar = useSnackbar()
  const apiError = useAuthApiError()

  function clearApiError() {
    apiError.clear()
  }

  function sbAlert(text: any, opts?: any) {
    snackbar.open({
      type: "error",
      showCloseButton: true,
      text,
      ...opts,
    })
  }

  function clearFormState() {
    formState.email = undefined as unknown as string
    formState.password = undefined as unknown as string
    formState.firstName = undefined as unknown as string
    formState.lastName = undefined as unknown as string
  }

  return {
    formState,
    clearApiError,
    sbAlert,
    clearFormState,
  }
}
