import { isDevelopment } from "std-env"

import type { PlanCode, PricingProductEnum } from "@finq/pricing/types"

export function useGtm() {
  const { $gtm } = useNuxtApp()
  const cfg = useAppConfig()

  /// --- Computed
  const isFirstTimeCookie = computed(() => $gtm.enabled.value === null)
  const isCookiesAccepted = computed(() => $gtm.enabled.value === true)

  /// --- Methods
  function consentUpdate({ approved }: { approved?: boolean } = { approved: true }) {
    const state = approved ? "granted" : "denied"

    // Dispatch consent approval event
    $gtm.gtag("consent", "update", {
      analytics_storage: state,
      ad_storage: state,
    })
  }

  function toggleCookies({ enabled: _enable = true }: { enabled?: boolean } = {}) {
    if (!cfg.analytics?.enabled) return
    let enabled = Boolean(_enable)

    $gtm.enabled.value = enabled
    consentUpdate({ approved: enabled })
  }

  return {
    /** Check if this is the first time the user visits the site */
    isFirstTime: isFirstTimeCookie,

    /** Check if cookies are accepted */
    cookiesAccepted: isCookiesAccepted,

    /** Enable cookies */
    enableCookies: () => toggleCookies({ enabled: true }),

    /** Disable cookies */
    disableCookies: () => toggleCookies({ enabled: false }),

    /** Sends a consent update to GTM */
    consentUpdate,

    /** Push data to the GTM dataLayer */
    push: $gtm.push,
  }
}

export interface DataLayerObject extends Record<string, any> {
  event?: string
}

export namespace GTM {
  export type SignupMethods = "user-password" | "facebook" | "google-oauth2"
  export type StatusTypes = "success" | "failure" | "pending"
  export type StatusChange = "rejected" | "approved"
  export type FormNames = "contact-us" | "b2b-contact"
  export type DepotNames =
    | "pensions main"
    | "pensions finqfull"
    | "pensions single fund page"
    | "stocks main"
    | "stocks finqfull"
    | "funds main (portal)"

  export type ProductArgs = {
    product_type: ProdCodesUnion | "" | undefined
    subscription_type: PlanCode
    period?: string
    currency?: "ILS" | "USD"
  }

  export type CheckoutArgs = ProductArgs & { value?: number | string; currency?: string }

  export type AuthArgs = { method: SignupMethods; email: string | undefined; user_id: string | undefined }

  export type PortfolioArgs = {
    product_type: "stocks" | "funds" | ProdCodesUnion
    portfolio_type: "etf" | "mutual_funds" | "finqfull" | undefined
    portfolio_id?: string
  }
  export type KycStepArgs = { step: string; step_name: string }

  // Methods
  export const getPortfolioType = (
    portfolioType: string | undefined // Could be  "A" | "ACTIVE" | "P" | "PASSIVE"
  ): GTM.PortfolioArgs["portfolio_type"] | undefined => {
    if (!portfolioType) return undefined
    return portfolioType === "A" || portfolioType === "ACTIVE" ? "mutual_funds" : "etf"
  }
}

export type DiscriminatedDataLayerArg =
  | { event: "page_view"; page_name: string; page_path: string; page_full_path: string; language: string }
  | { event: "modal_open"; action: string; referring_feature: string }
  | { event: "checkbox"; action: string; field_name: string }
  | ({ event: "set_user_data" } & Omit<GTM.AuthArgs, "method">)
  | ({ event: "signup_attempt" } & Omit<GTM.AuthArgs, "user_id">)
  | ({ event: "login_attempt" } & Omit<GTM.AuthArgs, "user_id">)
  | ({ event: "subscription_selected" } & GTM.CheckoutArgs)
  | ({ event: "notify_me_open" } & GTM.ProductArgs)
  | ({ event: "portfolio_type_selected" } & GTM.PortfolioArgs)
  | ({ event: "portfolio_selected" } & GTM.PortfolioArgs)
  | ({ event: "kyc_step_enter" } & GTM.KycStepArgs)
  | ({ event: "kyc_step_complete" } & GTM.KycStepArgs)
  | ({ event: "settings_update" } & {
      step_name: Nullable<string>
      status: GTM.StatusChange
      field_names?: string[]
    })
  | ({ event: "finq_agreement_signed" } & { product_type: PricingProductEnum; subscription_type: PlanCode })
  | ({ event: "form_submit" } & { email: string; form_name: GTM.FormNames })
  | ({ event: "search" } & {
      search_term: string
      results: number
      product_type: PricingProductEnum
      depot: GTM.DepotNames
      language: string
    })

export type SearchGtmConfig = Pick<
  Extract<DiscriminatedDataLayerArg, { event: "search" }>,
  "depot" | "product_type"
>
