export function useScrollUtils() {
  const { y: scrollPos } = useScroll(typeof window !== "undefined" ? window : null)

  const scrollTo = async (
    y: number | string | HTMLElement = 0,
    options?: gsap.TweenVars & { container?: HTMLElement | Window; offsetY?: number }
  ) => {
    const [{ default: gsap }, { ScrollToPlugin }] = await Promise.all([
      import("gsap"),
      import("gsap/ScrollToPlugin"),
    ])

    gsap.registerPlugin(ScrollToPlugin)

    if (y instanceof Event) y = 0

    const { container, offsetY, onComplete, ...otherProps }: any = {
      offsetY: null,
      container: window,
      onComplete: () => null,
      ...options,
    }

    return gsap.to(container, { scrollTo: { y, offsetY }, onComplete, ...otherProps })
  }

  return {
    scrollPos,
    scrollTo,
  }
}
