import { type InferOutput, any, array, number, object, optional, string } from "valibot"

export const PublicRoleSchema = object({
  id: number(),
  descriptionHe: string(),
  descriptionEn: string(),
})
export type PublicRole = InferOutput<typeof PublicRoleSchema>

export const SectionState = object({
  status: any(),
  description: string(),
  icon: optional(string()),
})

export type SectionState = InferOutput<typeof SectionState>
