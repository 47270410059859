<template>
  <Transition
    v-bind="{ ...cleanProps, ...transitionClasses }"
    @leave="onLeave"
    @after-leave="onAfterLeave"
    @before-enter="onBeforeEnter"
  >
    <slot />
  </Transition>
</template>

<script lang="ts">
import type { BaseTransitionProps, TransitionProps } from "vue"

import { omit } from "lodash-es"

export type FinqTransitionsKeys = keyof typeof FinqTransitions

export type Origins = "start" | "center" | "end" | "top" | "bottom" | "left" | "right"

export interface UiTransitionProps<T extends FinqTransitionsKeys>
  extends /* @vue-ignore */ BaseTransitionProps {
  name?: T
  classes?: Partial<(typeof FinqTransitions)[T & keyof typeof FinqTransitions]>
  origin?: `${number}% ${number}%` | `${number}px ${number}px` | `${Origins} ${Origins}` | Origins
  duration?: EnterLeaveStructure<TwTiming<"duration">>
  delay?: EnterLeaveStructure<TwTiming<"delay">>
  appear?: boolean
  hideOnLeave?: boolean
  leaveAbsolute?: boolean
  withOpacity?: boolean
}
</script>

<script setup lang="ts" generic="T extends FinqTransitionsKeys">
const props = withDefaults(defineProps<UiTransitionProps<T>>(), {})
const emits = defineEmits(["before-enter", "leave", "after-leave"])

//******************************************************** Computed ********************************************************\\

const hasTwDuration = computed(
  () => typeof props.duration === "string" && props.duration.startsWith("duration-")
)

const cleanProps = computed(() => {
  const cleanProps = omit(props, [
    "classes",
    "leaveAbsolute",
    "hideOnLeave",
    "origin",
    "withOpacity",
    "delay",
    "duration",
  ])

  if (cleanProps.name && isKey(FinqTransitions, cleanProps.name)) delete cleanProps.name

  return cleanProps as TransitionProps
})

const transitionClasses = computed((): TransitionClasses => {
  let { name, classes, duration, delay } = props

  if (name && !isKey(FinqTransitions, name)) return {} as any
  else name ??= "fade" as any
  const base = FinqTransitions[name as FinqTransitionsKeys]

  return {
    enterActiveClass: cn(
      base.enterActiveClass,
      classes?.enterActiveClass,
      getFromEnterLeaveProp(duration, "enter"),
      getFromEnterLeaveProp(delay, "enter")
    ),
    enterFromClass: cn(base.enterFromClass, classes?.enterFromClass, { "opacity-0": props.withOpacity }),
    enterToClass: cn(base.enterToClass, classes?.enterToClass, { "opacity-100": props.withOpacity }),
    leaveActiveClass: cn(
      base.leaveActiveClass,
      classes?.leaveActiveClass,
      getFromEnterLeaveProp(duration, "leave"),
      getFromEnterLeaveProp(delay, "leave")
    ),
    leaveFromClass: cn(base.leaveFromClass, classes?.leaveFromClass, { "opacity-100": props.withOpacity }),
    leaveToClass: cn(base.leaveToClass, classes?.leaveToClass, { "opacity-0": props.withOpacity }),
  }
})

//******************************************************** Methods ********************************************************\\

const onBeforeEnter: BaseTransitionProps["onBeforeEnter"] = (el) => {
  if (props.origin) {
    el.style.transformOrigin = props.origin
  }

  emits("before-enter", el)
}

const onLeave: BaseTransitionProps["onLeave"] = (el) => {
  if (props.leaveAbsolute) {
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = el

    el._transitionInitialStyles = {
      position: el.style.position,
      top: el.style.top,
      left: el.style.left,
      width: el.style.width,
      height: el.style.height,
    }

    el.style.position = "absolute"
    el.style.top = `${offsetTop}px`
    el.style.left = `${offsetLeft}px`
    el.style.width = `${offsetWidth}px`
    el.style.height = `${offsetHeight}px`
  }

  if (props.hideOnLeave) {
    el.style.setProperty("display", "none", "important")
  }

  emits("leave", el)
}

const onAfterLeave: BaseTransitionProps["onAfterLeave"] = (el) => {
  if (props.leaveAbsolute && el?._transitionInitialStyles) {
    const { position, top, left, width, height } = el._transitionInitialStyles

    delete el._transitionInitialStyles
    el.style.position = position || ""
    el.style.top = top || ""
    el.style.left = left || ""
    el.style.width = width || ""
    el.style.height = height || ""
  }

  emits("after-leave", el)
}
</script>
