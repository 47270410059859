export function useUtmCookies() {
  const oneWeekFromNow = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) /* 1 week */

  const ref = useCookie("finq_utm__ref", { expires: oneWeekFromNow })
  const adId = useCookie("finq_utm__ad_id", { expires: oneWeekFromNow })
  const lastClickRef = useCookie("finq_utm__last_click_ref", { expires: oneWeekFromNow })

  return { ref, adId, lastClickRef }
}

export function addUtmObject<T>(data: T): T & {
  utm: {
    ref: string | null | undefined
    adId: string | null | undefined
    lastClickRef: string | null | undefined
  }
} {
  const { ref, adId, lastClickRef } = useUtmCookies()

  return {
    ...data,
    utm: {
      ref: ref.value,
      adId: adId.value,
      lastClickRef: lastClickRef.value,
    },
  }
}
