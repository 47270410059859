<template>
  <LayoutHtmlBody>
    <LayoutDefaultAppContainer>
      <LayoutNavbarContainer with-logo elevated-navbar />

      <LazyErrorPageTemplate :status-code="error.statusCode">
        <div v-if="backdoorCount < 5" :style="{ height: '40px', width: '200px' }" @click="backdoorTap" />

        <UiExpandTransition>
          <div class="pre-container mt-l" v-if="backdoorCount >= 5" dir="ltr">
            <pre class="text-xs">{{ error.stack || JSON.stringify(error, null, 2) }}</pre>
          </div>
        </UiExpandTransition>
      </LazyErrorPageTemplate>
    </LayoutDefaultAppContainer>
  </LayoutHtmlBody>
</template>

<script setup lang="ts">
import type { NuxtError } from "nuxt/app"

const { error } = defineProps<{ error: NuxtError }>()
const backdoorCount = ref(0)
const backdoorTap = () => (backdoorCount.value >= 5 ? undefined : backdoorCount.value++)

watchEffect(() => {
  console.error(error)
})

definePageMeta({
  metaData: "meta.500",
  layout: "default",
})
</script>

<style scoped lang="scss">
.error-title {
  font-size: 10rem;
}

.pre-container {
  // mx-s p-s max-w-large overflow-x-scroll rounded bg-zinc-800 text-left
  margin-inline: theme("spacing.s");
  padding: theme("spacing.s");
  overflow-x: scroll;
  border-radius: theme("borderRadius.DEFAULT");
  background: #232325;
  color: rgba(150, 150, 159, 1);
  text-align: left;
  direction: ltr;
  max-width: 50rem;

  pre {
    max-width: 50rem;
  }
}
</style>
