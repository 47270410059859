<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue"

import {
  PopoverContent,
  type PopoverContentEmits,
  type PopoverContentProps,
  PopoverPortal,
  useForwardPropsEmits,
} from "radix-vue"

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<PopoverContentProps & { class?: HTMLAttributes["class"] }>(), {
  align: "center",
  sideOffset: 4,
})
const emits = defineEmits<PopoverContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <PopoverPortal>
    <PopoverContent
      v-bind="{ ...forwarded, ...$attrs }"
      :class="
        cn(
          'data-[state=open]:animate-in data-[state=closed]:animate-out',
          'data-[state=open]:fade-in-0 data-[state=closed]:fade-out-0',
          'text-popover-foreground border-black/6 z-60 mt-1.5 rounded-md border bg-white p-0 outline-none',
          'data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
          'data-[align=center]:origin-top ltr:data-[align=end]:origin-top-right ltr:data-[align=start]:origin-top-left rtl:data-[align=end]:origin-top-left rtl:data-[align=start]:origin-top-right',
          props.class
        )
      "
    >
      <slot />
    </PopoverContent>
  </PopoverPortal>
</template>
