import { type InferOutput, object, optional, string } from "valibot"

export namespace StockIndices {
  export const SYMBOL = {
    SNP500: "SNP500",
    NASDAQ100: "NASDAQ100",
    DOW30: "DOW30",
  } as const

  export const LABEL = {
    SNP500: "S&P 500",
    NASDAQ100: "Nasdaq 100",
    DOW30: "DOW 30",
  } as const
}

export type StockIndicesSymbol = (typeof StockIndices.SYMBOL)[keyof typeof StockIndices.SYMBOL]
export type StockIndicesLabel = (typeof StockIndices.LABEL)[keyof typeof StockIndices.LABEL]

export const stocksFiltersParamsSchema = object({
  ...sortByParamsSchema.entries,
  ...object({
    sectors: optional(string()),
  }).entries,
})

export type StocksFiltersParams = InferOutput<typeof stocksFiltersParamsSchema>
