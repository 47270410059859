<template>
  <UiButton
    class="min-md:flex-row-reverse gap-1 p-0 transition-all duration-200 hover:bg-transparent hover:text-black/50"
    v-if="cfg.i18n?.multiLanguage && !$route.meta?.layoutProps?.hideLanguageSwitcher"
    v-bind="attrs"
    replace
    without-locale-path
    variant="ghost"
    :id="genClickId('nav', 'language_switcher')"
    :to="switchLocalePath($i18n.locale === 'en' ? 'he' : 'en')"
  >
    <span class="font-normal" v-if="showText" v-text="$i18n.locale === 'he' ? 'עב' : 'EN'" />
    <LucideGlobe class="w-4" />
  </UiButton>
</template>

<script setup lang="ts">
import { LucideGlobe } from "lucide-vue-next"

const props = defineProps<{ showText?: boolean }>()

const cfg = useAppConfig()
const switchLocalePath = useSwitchLocalePath()
const attrs = useAttrs()
</script>
