<template>
  <UiPopoverRoot
    v-model:open="isPopoverOpen"
    :id="id"
    :modal="modal"
    @update:open="(open) => !open && emit('close', open)"
  >
    <UiPopoverTrigger :class="classes?.trigger" as-child>
      <slot name="trigger" />
    </UiPopoverTrigger>
    <UiPopoverContent
      :collision-padding="collisionPadding"
      :align="align"
      :class="classes?.body"
      :prioritize-position="prioritizePosition"
      @click="closePopupOnElementClick"
    >
      <slot />
    </UiPopoverContent>
  </UiPopoverRoot>
</template>

<script setup lang="ts">
import { ClassValue } from "clsx"
import type { PopoverContentProps, PopoverRootProps } from "radix-vue"

const isPopoverOpen = defineModel({ default: false })
const emit = defineEmits<{ (e: "close", value?: boolean): void }>()
type UiPopoverProps = {
  /**
   * @defaultValue "start"
   */
  align?: PopoverContentProps["align"]
  collisionPadding?: PopoverContentProps["collisionPadding"]
  /**
   * @defaultValue true
   */
  prioritizePosition?: PopoverContentProps["prioritizePosition"]

  id?: string
  modal?: PopoverRootProps["modal"]
  sticky?: PopoverContentProps["sticky"]
  classes?: Partial<{ body: ClassValue; trigger: ClassValue }>
}

withDefaults(defineProps<UiPopoverProps>(), {
  align: "start",
  prioritizePosition: true,
})

const closePopupOnElementClick = (e: Event) => {
  const tagName = (e.target as HTMLElement).tagName

  if (["A", "BUTTON"].includes(tagName)) {
    isPopoverOpen.value = false
  }
}
</script>
