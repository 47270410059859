type PathType = string | undefined | null

function testExternalUrl(path: PathType) {
  if (!path) return false
  const urlRegexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

  return urlRegexp.test(path)
}

function safeGetAssetUrl({ path, folder }: { path: PathType; folder: string }) {
  if (!path) return ""
  const isUrl: boolean = testExternalUrl(path)

  if (isUrl) return path

  return `/assets/${folder}/${path}`
}

export function getImageUrl(path: PathType): string {
  return safeGetAssetUrl({ path, folder: "images" })
}

export function getVideoUrl(path: PathType): string {
  return safeGetAssetUrl({ path, folder: "videos" })
}
