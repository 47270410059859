<template>
  <Component v-bind="props" :is="ResolvedIcon" :alt="imageAlt" />
</template>

<script setup lang="ts">
import { BaseIconProps } from "@finq/ui/components/ui/base/Icon.vue"

interface NuxtIconProps extends Omit<BaseIconProps, "icon"> {
  name: string
  imageAlt?: string
}

const props = withDefaults(defineProps<NuxtIconProps>(), {})

function toStartCase(str: string): string {
  return str
    .replace(/\/|-/g, " ") // Replace slashes and hyphens with spaces
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join("") // Join the words back together
}

const ResolvedIcon = computed(() => {
  try {
    return resolveComponent("Icon" + toStartCase(props.name))
  } catch (e) {
    return null
  }
})
</script>
