<template>
  <div :class="cn(spinnerVariants({ color, size, className: $attrs.class as any }))" role="status">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
        opacity=".25"
      ></path>
      <path
        fill="currentColor"
        d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"
      >
        <animateTransform
          attributeName="transform"
          dur="0.75s"
          repeatCount="indefinite"
          type="rotate"
          values="0 12 12;360 12 12"
        ></animateTransform>
      </path>
    </svg>
    <span class="sr-only">{{ $t("common.loading") }}</span>
  </div>
</template>

<script lang="ts">
const spinnerVariants = cva("inline-flex", {
  variants: {
    color: {
      default: "text-muted-foreground",
      primary: "text-primary",
      success: "text-success",
    },
    size: {
      xxs: "w-xxs h-xxs",
      xs: "w-xs h-xs",
      s: "w-s h-s",
      m: "w-m h-m",
      mx: "w-mx h-mx",
      l: "w-l h-l",
      xl: "w-xl h-xl",
    },
  },
  defaultVariants: {
    color: "default",
    size: "m",
  },
})

export type UiSpinnerVariantsProps = VariantProps<typeof spinnerVariants>
</script>

<script setup lang="ts">
import { type VariantProps, cva } from "class-variance-authority"

const props = defineProps<{
  color?: UiSpinnerVariantsProps["color"]
  size?: UiSpinnerVariantsProps["size"]
}>()
</script>
