<template>
  <UiLazyList
    :list="sortedSectors"
    :min-height="bp.isMobile.value ? 560 : 480"
    :list-item-props="() => ({ role: 'row' })"
  >
    <template #default="data">
      <ClientOnly>
        <PortalTableWidget
          :key="data.index"
          :config="{
            title: locale(data.item),
            subtitle: 'stocks.snp.sectors.subtitle',
            api: { params: { sectors: data.item.id, size: 5 } },
            moreLink: {
              name: 'stocks-portfolios-sectors-sectorId',
              params: { sectorId: data.item.id },
            },
            isInfinite: false,
            jsSort: true,
          }"
        />
      </ClientOnly>
    </template>
  </UiLazyList>
</template>

<script lang="ts">
import { orderBy } from "lodash-es"
import { mapState } from "pinia"

export default {
  name: "AllSectors",

  setup() {
    definePageMeta({
      metaData: "meta.stocks.snp500.sectors",
      page_title: "stocks.sectors",
      ultimateOnly: true,
    })

    const { locale } = useI18nUtils()
    const bp = useDisplay()

    return { locale, bp }
  },

  computed: {
    ...mapState(useStocksStore, {
      sectors: (state) => state.sectors,
      loading: (state) => state.isSectorsLoading,
    }),

    sortedSectors() {
      return orderBy(this.sectors, ["name"], ["asc"]).map((item) => ({
        ...item,
        to: withQueryParams({ name: "stocks-portfolios-sectors-sectorId", params: { sectorId: item.id } }),
        title: this.locale(item),
        image: item.imagePath,
      }))
    },
  },
}
</script>
