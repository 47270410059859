/** Transforms text nodes by replacing certain patterns with HTML tags. */
export function transformTextMarks(text: Nullable<string>): string {
  if (!text) return ""
  return (
    text
      // **text** -> <strong>text</strong>
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
      // text \n:md text -> text <br class="hidden md:block"/> text
      .replace(/\n:md/g, "<br class='hidden md:block'/>")
      // text \n text -> text <br> text
      .replace(/\n/g, "<br>")
  )
}
