import { UiButton } from "#components"

import type { RoutesPathsList } from "@finq/app-base/composables/routes-utils"

export namespace Disclaimer {
  export type Translation = "default" | "trademarks" | "stocks" | "pension" | "funds" | "profile-investments"
  export type Sources = Record<string | "default", Record<string, Translation>> | Translation
  export type SourceMap = Partial<Record<RoutesPathsList, Disclaimer.Sources>>
}

export function getSourceFromMap(map: Disclaimer.SourceMap) {
  const route = useRoute()
  const { path, params } = route
  if (!path) return "default"

  const currentRouteActualPath = route.matched.at(-1)?.path

  const closestMapParent = Object.keys(map).find((key) =>
    stripRoutePathPrefix(currentRouteActualPath)?.startsWith(key)
  )
  const source = map[path as RoutesPathsList] || map[closestMapParent as RoutesPathsList]

  if (typeof source === "string") {
    return source
  } else if (typeof source === "object" && params) {
    for (const paramKey in params) {
      if (!isKey(params, paramKey)) continue

      const paramValue = params?.[paramKey]
      const sourceByParam = source[paramKey]

      if (sourceByParam && sourceByParam.hasOwnProperty(paramValue)) {
        const resolvedSource = sourceByParam[paramValue]
        return resolvedSource
      }
    }
  }

  return "default"
}

export const RefinitivePopup: FunctionalComponent = (props, { slots }) => {
  const popup = usePopup()
  return (
    <UiButton
      unset-all
      onClick={() =>
        popup.open({
          title: "Refinitive",
          content:
            "The Refinitiv Logo and Refinitiv are trademarks of Refinitiv and its affiliated companies in the United States and other countries and used herein under license. Copyright ©Refinitiv, 2021. All Rights Reserved. Use, duplication, or sale of this service, or data contained herein, except as described in the Stocks Ranking Tool subscription agreement, is strictly prohibited.",
        })
      }
    >
      {slots.default?.()}
    </UiButton>
  )
}
