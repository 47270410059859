import { isDevelopment } from "std-env"

import { GlobalAppConfigInput } from "../../../lib/global"

export default defineAppConfig<GlobalAppConfigInput>({
  app: {
    domain: "finqai.com",
  },

  i18n: {
    multiLanguage: false,
    defaultLocale: "en",
  },

  analytics: {
    enabled: !isDevelopment,
    gtmAlwaysEnabled: true,
  },

  payments: {
    service: "stripe",
  },
} satisfies GlobalAppConfigInput)
