<template>
  <NuxtLink
    v-bind="$attrs"
    :to="fixedLink"
    :external="isExternalLink"
    :target="target ?? isExternalLink ? '_blank' : undefined"
    :replace="replace"
    :class="class"
    :prefetch="prefetch ?? !isExternalLink"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import { type ClassValue } from "clsx"

export interface UiLinkProps {
  href?: string
  to?: TypedRouteProps | string
  disabled?: boolean
  replace?: boolean
  class?: ClassValue
  withoutLocalePath?: boolean
  target?: "_blank" | "_self" | "_parent" | "_top"
  prefetch?: boolean
}

const props = withDefaults(defineProps<UiLinkProps>(), { disabled: false, prefetch: false })

const localePath = useLocalePath()

const toOrHref = computed(() => props.to || props.href!)

const isExternalLink = computed(() => {
  if (typeof toOrHref.value !== "string") return false

  return toOrHref.value.startsWith("http")
})

const fixedLink = computed(() => {
  if (props.withoutLocalePath || isExternalLink.value) return toOrHref.value

  return localePath(toOrHref.value as any)
})
</script>
