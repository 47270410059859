<template>
  <div class="tablet:flex tablet:flex-col flex flex-1 flex-col gap-6">
    <h3 class="text-sm font-medium">{{ props.title }}</h3>

    <ul class="flex flex-col gap-3">
      <li class="text-sm/5 font-light" v-for="(item, index) in props.items" :key="index">
        <UiLink :to="item.to">
          {{ item.title }}
        </UiLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { FooterItem } from "@finq/app-base/components/layout/Footer/Footer.vue"

interface FooterItemsListProps {
  title: string
  items: FooterItem[]
}

const props = defineProps<FooterItemsListProps>()
</script>
