<template>
  <div :class="classNames.root">
    <div v-if="modelValue" :class="classNames.dot">
      <slot name="badge" />
    </div>
    <slot />
  </div>
</template>

<script lang="ts">
export const badgeVariants = cva(
  "absolute inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-muted-foreground border-2 z-50 border-white rounded-full",
  {
    variants: {
      placement: {
        default: "-top-2 -start-2",
        "bottom-start": "-bottom-2 -start-2",
        "bottom-end": "-bottom-2 -end-2",
      },
    },
    defaultVariants: {
      placement: "default",
    },
  }
)

interface BadgeClasses {
  root?: ClassValue
  dot?: ClassValue
}
</script>
<script setup lang="ts">
import { VariantProps, cva } from "class-variance-authority"

const { placement, classes } = defineProps<{
  modelValue?: boolean
  placement?: VariantProps<typeof badgeVariants>["placement"]
  classes?: BadgeClasses
}>()

const classNames = computed(() => ({
  root: cn(
    "relative inline-flex items-center rounded-lg text-center text-sm font-medium text-white",
    classes?.root
  ),
  dot: cn(badgeVariants({ placement }), classes?.dot),
}))
</script>
