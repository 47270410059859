<template>
  <PageProfileLayout :tabs="sectionsManager.tabs" :current-tab="currentTab" @onTabChange="scrollToSection">
    <UiLoadingAnimation :loading="loading" style="width: 100%" container-size="50vh">
      <main class="my-profile-sections" ref="sections">
        <Component
          v-for="item in sectionsManager.grid"
          v-bind="item.props!"
          :is="item.component"
          :key="item.sectionName"
          @ready:ui="addUiReadyComponent(item.sectionName)"
          @ready:logic="addLogicReadyComponent(item.sectionName)"
        />
      </main>
    </UiLoadingAnimation>
  </PageProfileLayout>
</template>

<script lang="ts">
import type { RouteLocation } from "#vue-router"
import { cloneDeep, isEmpty } from "lodash-es"
import { mapActions, mapState } from "pinia"

import { User } from "@finq/app-base/lib/types/user"

import { PopupAction, PopupMessage } from "@finq/ui/types/popup"

import { useProfileStore } from "../../composables/stores/profile"
import { getDifferences } from "../../utils/profile/differences"
import { useProfileSectionsManager } from "../../utils/profile/useProfileSectionsManager"

export default {
  name: "MyProfile",
  //   mixins: [
  //     MustLoginPopup,
  //     // TriggeredSectionsMixin,
  //     UnsavedChangesMixin,
  //     ProfilePlanSectionsManager,
  //     ReactiveProvideMixin({ name: "$profile" }),
  //   ],

  setup() {
    definePageMeta({
      metaData: "meta.my_profile",
      layoutProps: {
        hideFooter: true,
      },
    })

    const { t } = useI18n()
    const user = useUser()
    const bp = useDisplay()
    const popup = usePopup()
    const { scrollTo } = useScrollUtils()
    const { sectionsManager } = useProfileSectionsManager()
    const readyUiComponents = ref([] as string[])
    const readyLogicComponents = ref([] as string[])
    const saveProfileBus = useEventBus("saveProfile")
    const afterSaveBus = useEventBus("afterSave")

    const allLogicComponentsReady = computed(() => {
      // Waiting until each inner form component will declare its ready
      return readyLogicComponents.value.length === sectionsManager.value.grid?.length // todo replace with programmable length
    })

    const allUiComponentsReady = computed(() => {
      // Waiting until each inner form component will declare its ready
      return readyUiComponents.value.length === sectionsManager.value.grid?.length // todo replace with programmable length
    })

    const addUiReadyComponent = (id: string) => {
      if (readyUiComponents.value.includes(id)) return
      readyUiComponents.value = [...readyUiComponents.value, id]
    }
    const addLogicReadyComponent = (id: string) => {
      if (readyLogicComponents.value.includes(id)) return
      readyLogicComponents.value = [...readyLogicComponents.value, id]
    }

    return {
      t,
      bp,
      user,
      popup,
      sectionsManager,
      saveProfileBus,
      afterSaveBus,

      allLogicComponentsReady,
      allUiComponentsReady,
      addUiReadyComponent,
      addLogicReadyComponent,

      readyUiComponents,
      readyLogicComponents,
      scrollTo,
    }
  },

  data: () => ({
    currentTab: 0,
    isScrolling: false,
    loading: false,
    changedUserData: {},
    isUploading: false,

    uploadingProgress: 0,
  }),

  watch: {
    allLogicComponentsReady: {
      immediate: true,
      handler(ready) {
        if (!ready || !this.allUiComponentsReady) return
        // Tell the profile progress to use calculated value instead of fetched value
        this.setProfileMountDone(true)

        this.scrollToQuerySection()

        if (this.user.user.value?.profileProgress !== (this.savedUser.profileProgress ?? 0))
          this.onSave({ noValidate: true })
      },
    },

    savedUser: {
      deep: true,
      immediate: true,
      handler() {
        this.changedUserData = getDifferences(this.savedUser, this.user.user.value!)
        if (
          Object.keys(this.changedUserData).every((key) => key === "profileProgress") &&
          Object.keys(this.changedUserData).length === 1
        ) {
          return
        }

        this.setDirtyUserData(Object.keys(this.changedUserData) as (keyof User)[])
      },
    },
  },

  computed: {
    ...mapState(useProfileStore, {
      savedUser: (state) => state.savedUser,
      saving: (state) => state.isSaving,
      profileMountDone: (state) => state.profileMountDone,
      invalidForms: (state) => state.getInvalidForms,
    }),
  },

  methods: {
    ...mapActions(useProfileStore, {
      setSavedUser: "setSavedUser",
      setDirtyUserData: "setDirtyUserData",
      toggleSaving: "toggleSaving",
      setProfileMountDone: "setProfileMountDone",
    }),

    scrollToQuerySection() {
      if (isNullOrUndefined(this.$route.query.sect)) return

      if (this.$route.query.sect) this.scrollToSection(+this.$route.query.sect)
    },

    scrollToSection(tabIndex: number) {
      if (this.currentTab !== null) {
        this.isScrolling = true

        const sectionName = this.sectionsManager.tabs?.[tabIndex]?.value

        this.$eventBus.$emit(`request-expand:${sectionName + "Section"}`)

        const target = tabIndex === 0 ? 0 : "#" + sectionName + "Section"
        const offsetY = this.bp.isMobile.value ? 90 : 100

        this.scrollTo(target, {
          offsetY,
          delay: 0.14,
          onComplete: () => {
            this.isScrolling = false
          },
        })
      }

      this.currentTab = tabIndex
    },

    scrollToFirstInvalid() {
      const offsetY = this.bp.isMobile.value ? 150 : 200

      this.scrollTo("#" + this.invalidForms[0].selector, { offsetY })
    },

    async initUserObject() {
      this.loading = true
      try {
        const meParams = {} as any

        if (this.user.user?.value?.bankAccountExist) meParams.extended = 1

        await UserService.getMe({ params: meParams }).then(this.user.mergeUser)

        this.setSavedUser(cloneDeep(this.user.user.value)!)
      } catch (err) {
        console.error(err)
        // TODO: implement this!
        // this.openGenericPopup(
        //   "fail_on_load",
        //   // TODO: implement this!   { errorObject: err.response.data.error },
        //   {},
        //   this.handleMustLoginAction("profile-info"),
        // );
      } finally {
        this.loading = false
      }
    },

    async saveForm(sectionSelectorId: string | null = null) {
      const baseGtmEvent = {
        step_name: sectionSelectorId,
        field_names: Object.keys(this.changedUserData),
      } as const

      try {
        this.toggleSaving(true)

        let res = null

        if (!isEmpty(this.changedUserData)) res = await UserService.saveMe(this.changedUserData)

        if (res) {
          this.user.mergeUser(res)
          this.setSavedUser(cloneDeep(this.user.user.value)!)
        }

        this.$gtm.push({ event: "settings_update", status: "approved", ...baseGtmEvent })

        this.afterSaveBus.emit()
      } catch (err) {
        console.error(err)
        this.handleSaveFormCatch(err)

        this.$gtm.push({ event: "settings_update", status: "rejected", ...baseGtmEvent })
      } finally {
        this.toggleSaving(false)
      }
    },

    async onSave({ noValidate = false, callback = () => {}, sectionSelectorId = null } = {}) {
      if (!noValidate) {
        this.$eventBus.$emit("validateBeforeSave")
      }

      if (isEmpty(this.changedUserData)) {
        return
      } else if (this.invalidForms.length) {
        if (noValidate) return

        return this.openGenericPopup("no_changes", {}, this.scrollToFirstInvalid)
      }

      await this.saveForm(sectionSelectorId)

      // Calling callback if exists
      callback?.()
    },

    // Popups / Error handling

    handleSaveFormCatch(err: any) {
      console.error(err)
      if (this.isUploading) {
        this.isUploading = false
      }
      // TODO: implement this!
      //   switch (err.response?.data?.error?.code) {
      //     case 401:
      //       return this.mustLoginPopup();
      //     default:
      //       return this.openGenericPopup(
      //         "fail_on_save",
      //         {
      //           errorObject: err?.response ? err.response.data.error : err,
      //         },
      //         () => {
      //           if (this.$route.name !== "profile-info") this.$router.push(this.localPath({ name: "profile-info" }));
      //         },
      //       );
      //   }
    },

    openGenericPopup(key = "fail_on_save", params: Partial<PopupMessage>, onComplete = () => {}) {
      if (!key) return
      const onClose = () => {
        onComplete()
      }

      this.popup.open({
        type: "warning",
        title: this.t(`profile_page.popups.${key}.title`),
        content: this.t(`profile_page.popups.${key}.text`),
        action: { label: this.t("common.back"), action: onClose },
        close: onClose,
        ...params,
      })
    },

    openUnsavedChangesPopup(
      callbacks: { accept?: PopupAction["action"]; cancel?: PopupAction["action"] },
      { title, text }: { title: string; text: string }
    ) {
      this.popup.open({
        type: "warning",
        title,
        content: text,
        action: { label: this.t("common.yes"), action: callbacks?.accept },
        cancel: { label: this.t("common.no"), action: callbacks?.cancel },
      })
    },
  },

  beforeRouteLeave(to: RouteLocation, from: RouteLocation, next: VoidFunction) {
    if (isEmpty(this.changedUserData) || this.invalidForms.length) {
      return next()
    } else if (
      /**
       * if changes contains only profile progress,
       * than dont display changes popup and let user navigate.
       * WHY? in order to calculate the profile progress we need to wait for api's to be fetched (banks/documents).
       * if the user navigated before the profile finished saving them, then give up saving them
       */
      Object.keys(this.changedUserData).every((key) => key === "profileProgress")
    ) {
      next()
    } else {
      this.openUnsavedChangesPopup(
        {
          cancel: next,
          accept: async () => {
            await this.saveForm()
            next()
          },
        },
        {
          title: this.t("profile_page.popups.save.title"),
          text: this.t("profile_page.popups.save.text"),
        }
      )
    }
  },

  async beforeMount() {
    await this.initUserObject()
    this.saveProfileBus.on(this.onSave as any)
  },

  unmounted() {
    this.saveProfileBus.off(this.onSave as any)
  },
}
</script>

<style lang="scss" scoped>
.my-profile {
  &-sections {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: theme.$spacing-m;
    margin-bottom: theme.$spacing-xl + theme.$spacing-l;
  }

  @include theme.media("<md") {
    padding-top: theme.$spacing-s !important;

    &-sections {
      gap: theme.$spacing-s;
    }
  }
}
</style>
