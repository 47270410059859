import { type ClassValue, clsx } from "clsx"
import { extendTailwindMerge } from "tailwind-merge"

// prettier-ignore
const allSpacings = ["xxxs", "xxs", "xs", "s", "m", "mx", "l", "xl", "xxl", "large"] as const

const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      spacing: allSpacings,
      borderRadius: ["sm", "md", "lg"],
      minHeight: ["2", "4", "36", ...allSpacings],
      minWidth: ["2", "4", "36", ...allSpacings],
      maxWidth: ["large", ...allSpacings],
      height: ["screen-nav"],
      backgroundImage: ["card-svg"],
    },
  },
})

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
