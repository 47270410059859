import { array } from "valibot"

import { PlanCode, PlanPeriodType, PricingProductEnum } from "@finq/pricing/types"

import { BaseApiService, afterLogoutActions } from "./api.service"

// For now we are only using /marketing endpoint from this one,
// TODO: add other endpoints
class UserServiceRaw extends BaseApiService {
  public getMe = async (config: KyOptions = {}) => {
    const res = await this.instance.get("/users/me", config)

    return this.getData(res)
  }

  // TODO: create a zod schema for this....
  public createOrUpdateMarketingUser = async (data: Partial<User>, config: KyOptions = {}) => {
    const { saveUser } = useUser()
    const res = await this.instance.post("/users/marketing", data, config).then(this.getData)

    return saveUser(res)
  }

  // convert to anonymous function
  public saveMe = async (data: Partial<User>, config: KyOptions = {}) => {
    const res = await this.instance.post("/users/me", data, config)

    return this.getData(res)
  }

  public updateAuthenticatedUser = async (data: Partial<User>, config: KyOptions = {}) => {
    const { mergeUser } = useUser()
    const res = await this.saveMe(data, config)

    return mergeUser(res)
  }

  public getPublicRoles = async (params?: any, config: KyOptions = {}) => {
    const res = await this.instance.get("/publicroles", params)

    return this.parse(this.getData(res), array(PublicRoleSchema))
  }

  public getCountries = async (params?: any, config: KyOptions = {}) => {
    return this.getData(await this.instance.get("/countries", params))
  }

  public getOccupations = async (params?: any, config: KyOptions = {}) => {
    return this.getData(await this.instance.get("/occupations", params))
  }

  public notifyMe = async (
    data: {
      productId: PricingProductEnum
      productPlanCode: PlanCode
      productPlanPricingType: PlanPeriodType
      email: string
    },
    config: KyOptions = {}
  ) => {
    return await this.instance.post("/users/temp-notifyme", addUtmObject(data), config)
  }

  public logout = async () => {
    const localePath = useLocalePath()
    const user = useUser()
    const accessToken = user.user?.value?.accessToken

    await navigateTo(localePath({ name: "index" }))
    afterLogoutActions()

    return this.instance.post("/auth/logout", {}, { headers: { Authorization: `Bearer ${accessToken}` } })
  }
}

/** Exporting a singleton object to entire app */
export const UserService = new UserServiceRaw()
