import type { CSSProperties } from "vue"

import { getTransitionSizes } from "@formkit/auto-animate"

export type TransitionClasses = {
  enterActiveClass: string
  enterFromClass: string
  enterToClass: string
  leaveActiveClass: string
  leaveFromClass: string
  leaveToClass: string
}

export const FinqTransitions = {
  scrollY: {
    enterActiveClass: "transition duration-200 ease-out",
    enterFromClass: "transform -translate-y-4 opacity-0",
    enterToClass: "transform translate-y-0 opacity-100",
    leaveActiveClass: "transition duration-150 delay-75 ease-in",
    leaveFromClass: "transform translate-y-0 opacity-100",
    leaveToClass: "transform translate-y-1 opacity-0",
  },
  scrollYReverse: {
    enterActiveClass: "transition duration-200 ease-out",
    enterFromClass: "transform translate-y-4 opacity-0",
    enterToClass: "transform -translate-y-0 opacity-100",
    leaveActiveClass: "transition duration-150 delay-75 ease-in",
    leaveFromClass: "transform -translate-y-0 opacity-100",
    leaveToClass: "transform -translate-y-1 opacity-0",
  },
  scrollX: {
    enterActiveClass: "transition duration-200 ease-out",
    enterFromClass: "transform -translate-x-4 rtl:translate-x-4 opacity-0",
    enterToClass: "transform translate-x-0 opacity-100",
    leaveActiveClass: "transition duration-150 delay-75 ease-in",
    leaveFromClass: "transform translate-x-0 opacity-100",
    leaveToClass: "transform translate-x-1 rtl:-translate-x-1 opacity-0",
  },
  scrollXReverse: {
    enterActiveClass: "transition duration-200 ease-out",
    enterFromClass: "transform translate-x-4 rtl:-translate-x-4 opacity-0",
    enterToClass: "transform translate-x-0 opacity-100",
    leaveActiveClass: "transition duration-150 delay-75 ease-in",
    leaveFromClass: "transform translate-x-0 opacity-100",
    leaveToClass: "transform -translate-x-1 rtl:translate-x-1 opacity-0",
  },
  slideX: {
    enterActiveClass: "transition duration-300 ease-out",
    enterFromClass: "opacity-0 md:translate-x-full ltr:-translate-x-full",
    enterToClass: "opacity-100 md:translate-x-0",
    leaveActiveClass: "duration-100 ease-in",
    leaveFromClass: "opacity-100 md:translate-x-0",
    leaveToClass: "opacity-0 md:translate-x-full ltr:-translate-x-full",
  },
  slideY: {
    enterActiveClass: "transition duration-300 ease-out",
    enterFromClass: "opacity-0 translate-y-full",
    enterToClass: "opacity-100 translate-y-0",
    leaveActiveClass: "duration-100 ease-in",
    leaveFromClass: "opacity-100 translate-y-0",
    leaveToClass: "opacity-0 translate-y-full",
  },
  slideYReverse: {
    enterActiveClass: "transition duration-300 ease-out",
    enterFromClass: "transform -translate-y-full",
    enterToClass: "transform translate-y-0",
    leaveActiveClass: "transition duration-150 delay-75 ease-in",
    leaveFromClass: "transform translate-y-0",
    leaveToClass: "transform -translate-y-4",
  },
  fade: {
    enterActiveClass: "transition duration-200 ease-out",
    enterFromClass: "opacity-0",
    enterToClass: "opacity-100",
    leaveActiveClass: "transition duration-150 ease-in",
    leaveFromClass: "opacity-100",
    leaveToClass: "opacity-0",
  },
  scale: {
    enterActiveClass: "transition duration-200 ease-out",
    enterFromClass: "transform scale-95 opacity-0",
    enterToClass: "transform scale-100 opacity-100",
    leaveActiveClass: "transition duration-150 delay-75 ease-in",
    leaveFromClass: "transform scale-100 opacity-100",
    leaveToClass: "transform scale-95 opacity-0",
  },

  expand: {
    enterActiveClass: "transition duration-300 ease-in-out",
    enterFromClass: "transform",
    enterToClass: "transform",
    leaveActiveClass: "transition duration-300 ease-in-out",
    leaveFromClass: "transform",
    leaveToClass: "transform",
  },
}

export type EnterLeaveStructure<T extends any = string> =
  | T
  | { enter: T }
  | { leave: T }
  | { enter: T; leave: T }

export type TwTiming<T extends string> = `${T}-${
  | 0
  | 75
  | 100
  | 150
  | 200
  | 300
  | 500
  | 700
  | 1000
  | `[${number}${"ms" | "s"}]`}`

export const getFromEnterLeaveProp = <T extends EnterLeaveStructure>(
  prop: T | undefined,
  lookFor: keyof T | "enter" | "leave"
) => {
  if (typeof prop === "string" || !prop?.[lookFor as keyof T]) return prop

  return prop[lookFor as keyof T]
}

export const autoAnimateFade = (
  el: Element,
  action: "add" | "remove" | "remain",
  oldCoords?: any,
  newCoords?: any
) => {
  let keyframes: Keyframe[]
  switch (action) {
    case "add":
      keyframes = [{ opacity: 0 }, { opacity: 1 }]
      break
    case "remove":
      keyframes = [{ opacity: 0 }, { opacity: 0 }]
      break
    case "remain":
      const deltaX = oldCoords!.left - newCoords!.left
      const deltaY = oldCoords!.top - newCoords!.top
      const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(el, oldCoords!, newCoords!)
      const start: any = {
        opacity: 0,
        transform: `translate(${deltaX}px, ${deltaY}px)`,
      }
      const mid: any =
        deltaX === 0 && deltaY === 0
          ? { transform: `translate(0,0)` }
          : {
              transform: `translate(${deltaX * 0.05}px, ${deltaY * 0.05}px)`,
            }
      const end: any = {
        opacity: 1,
        transform: "translate(0px, 0px)",
      }

      if (widthFrom !== widthTo) {
        start.width = `${widthFrom}px`
        mid.width = `${(widthFrom + widthTo) / 2}px`
        end.width = `${widthTo}px`
      }
      if (heightFrom !== heightTo) {
        start.height = `${heightFrom}px`
        mid.height = `${(heightFrom + heightTo) / 2}px`
        end.height = `${heightTo}px`
      }
      keyframes = [start as Keyframe, mid as Keyframe, end as Keyframe]
      break
    default:
      const _invalidAction: never = action
      throw new Error(`Invalid action: ${_invalidAction}`)
  }
  return new KeyframeEffect(el, keyframes, { duration: 300, easing: "ease-out" })
}

export const autoAnimateFly = (
  el: Element,
  action: "add" | "remove" | "remain",
  oldCoords?: any,
  newCoords?: any
) => {
  let keyframes: Keyframe[]
  switch (action) {
    case "add":
      keyframes = [
        { transform: "translateY(-100%)", opacity: 0, zIndex: -1 },
        { transform: "translateY(0)", opacity: 1, zIndex: 1 },
      ]
      break
    case "remove":
      keyframes = [
        { transform: "translateY(0)", opacity: 1, zIndex: 1 },
        { transform: "translateY(100%)", opacity: 0, zIndex: -1 },
      ]
      break
    case "remain":
      const deltaX = oldCoords!.left - newCoords!.left
      const deltaY = oldCoords!.top - newCoords!.top
      const [widthFrom, widthTo, heightFrom, heightTo] = getTransitionSizes(el, oldCoords!, newCoords!)
      const start: CSSProperties = {
        transform: `translateY(${deltaY}px)`,
        opacity: 1,
      }
      const mid: CSSProperties =
        deltaX === 0 && deltaY === 0
          ? { transform: `translateY(0)` }
          : {
              transform: `translateY(${deltaY === 0 ? "100%" : deltaY * 0.5 + "px"})`,
              opacity: 0,
            }
      const end: CSSProperties = {
        transform: "translateY(0)",
        opacity: 1,
      }
      if (widthFrom !== widthTo) {
        start.width = `${widthFrom}px`
        mid.width = `${(widthFrom + widthTo) / 2}px`
        end.width = `${widthTo}px`
      }
      if (heightFrom !== heightTo) {
        start.height = `${heightFrom}px`
        mid.height = `${(heightFrom + heightTo) / 2}px`
        end.height = `${heightTo}px`
      }
      keyframes = [start as Keyframe, mid as Keyframe, end as Keyframe]
      break
    default:
      const _invalidAction: never = action
      throw new Error(`Invalid action: ${_invalidAction}`)
  }
  return new KeyframeEffect(el, keyframes, { duration: 600, easing: "ease-in-out" })
}
