interface HotJarAttributes {
  [`FINQ User ID`]: string
  [`User Waiting list`]: string[]
  ["Is in subscription page"]: boolean
  ["User Paid"]: boolean
  ["Paid and returned from Stripe"]: boolean
  ["Signed up pre auth"]: boolean
  ["Signed up post auth"]: boolean
  [key: string]: string | string[] | boolean
}

export function setHotJarAttributes(data: Partial<HotJarAttributes>) {
  if (typeof window === "undefined" || !window?.hj) return
  const user = useUser()

  return window.hj("identify", user.user.value?.id, jsonClean(data))
}

export function useSetHotJarAttributes(data: globalThis.ComputedRef<Partial<HotJarAttributes>>) {
  watchEffect(() => {
    setHotJarAttributes(data.value)
  })

  return { setHotJarAttributes }
}
