<template>
  <LayoutHtmlBody>
    <LayoutNavbar
      v-if="!$route.meta.layoutProps?.hideNavbar"
      :back-button="$route.meta.layoutProps?.backButton"
      :hide-user-actions="$route.meta.layoutProps?.hideUserActions"
      :close-button="$route.meta.layoutProps?.closeButton"
      :hide-links="$route.meta.layoutProps?.hideLinks"
    />

    <NuxtLayout>
      <!-- // Add more global components here -->
      <ClientOnly>
        <LazyLayoutAppWidgetWrapper />
        <LazyLayoutCookiesModal />

        <NuxtLazyHydrate :when-idle="4000">
          <LazyAuthWidget />
          <LazyUiSnackbarFactory />
          <LazyUiDialogFactory />
        </NuxtLazyHydrate>
      </ClientOnly>

      <NuxtLoadingIndicator :throttle="800" />

      <NuxtPage />
    </NuxtLayout>

    <LayoutFooter class="mt-auto" v-if="!$route.meta.layoutProps?.hideFooter" />
  </LayoutHtmlBody>
</template>

<script setup lang="ts">
// Initialize the app
useAppInit()
</script>
