import type { RouteLocation } from "#vue-router"

type NavigateToOptions = Parameters<typeof navigateTo>[1]

export function useRouterUtils() {
  const router = useRouter()
  const localePath = useLocalePath()

  return {
    route: router.currentRoute,
    push(route: TypedRouteProps | string | undefined, options: NavigateToOptions = {}) {
      return navigateTo(localePath(route as RouteLocation), options) as Promise<void>
    },
    replace(route: TypedRouteProps | string | undefined, options: NavigateToOptions = {}) {
      return navigateTo(localePath(route as RouteLocation), { ...options, replace: true }) as Promise<void>
    },
  }
}
