<script setup lang="ts">
import { LucideX } from "lucide-vue-next"
import {
  DialogClose,
  DialogContent,
  type DialogContentEmits,
  type DialogContentProps,
  DialogOverlay,
  DialogPortal,
  useForwardPropsEmits,
} from "radix-vue"

import { UiDialogContentProps } from "@finq/ui/components/ui/Dialog/DialogContent.vue"

const props = defineProps<DialogContentProps & UiDialogContentProps>()
const emits = defineEmits<DialogContentEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <DialogPortal>
    <DialogOverlay />

    <DialogContent
      v-bind="forwarded"
      :aria-describedby="undefined"
      :class="
        cn(
          'border-border bg-background z-60 relative my-0 grid w-full max-w-lg gap-4 rounded-3xl border p-6 shadow-lg !duration-300 md:w-full md:rounded-lg',
          props.class,
          props.classes?.panel
        )
      "
      @pointer-down-outside="
        (event) => {
          const originalEvent = event.detail.originalEvent
          const target = originalEvent.target as HTMLElement
          if (originalEvent.offsetX > target.clientWidth || originalEvent.offsetY > target.clientHeight) {
            event.preventDefault()
          }
        }
      "
    >
      <slot />

      <DialogClose v-if="!hideCloseButton" as-child>
        <UiButton
          v-if="!hideCloseButton"
          :class="cn('absolute end-5 top-5 z-[60] md:end-4 md:top-4', classes?.close)"
          variant="icon"
        >
          <LucideX :size="20" />
          <span class="sr-only">Close</span>
        </UiButton>
      </DialogClose>
    </DialogContent>
  </DialogPortal>
</template>
