<template>
  <div class="font-light text-black/75">
    <p>{{ $t("system.logout_due_inactivity") }}</p>
    <i18n-t keypath="system.login_to_proceed" tag="p" scope="global">
      <template #login>
        <UiButton class="h-fit p-0 text-base" variant="link" @click="emit('login')">
          {{ $t("system.button.login") }}
        </UiButton>
      </template>
    </i18n-t>
  </div>
</template>

<script setup lang="ts">
const { t: $t } = useI18n({ useScope: "global" })

const emit = defineEmits(["login"])
</script>
