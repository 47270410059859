import { type Composer, type UseI18nOptions } from "vue-i18n"

import { PricingProductEnum } from "./../types/index"
import { type MappedProduct } from "./store/usePricingStore"

type UseProductsOptions = UseI18nOptions & {
  instance?: Composer
}
export function useProducts(options?: UseProductsOptions) {
  const { tmsafe } = useI18nUtils({ instance: options?.instance })

  const products = computed(() => tmsafe("products") as MappedProduct)

  function isProductExist(productId: PricingProductEnum) {
    return isKey(products.value, productId)
  }

  function getProductById(productId: PricingProductEnum) {
    return isProductExist(productId) ? products.value[productId] : ({} as MappedProduct[PricingProductEnum])
  }

  return {
    products,
    isProductExist,
    getProductById,
  }
}
