// Nuxt 3 app aliases
import type { DehydratedState, VueQueryPluginOptions } from "@tanstack/vue-query"
import { QueryClient, VueQueryPlugin, dehydrate, hydrate } from "@tanstack/vue-query"
import { HTTPError } from "ky"
import { merge } from "lodash-es"
import { isDevelopment } from "std-env"
import { ValiError } from "valibot"

const IGNORE_RETRY_STATUS_CODES = [401, 404]

export default defineNuxtPlugin({
  name: "vue-query",
  parallel: true,
  setup(nuxt) {
    const vueQueryState = useState<DehydratedState | null>("vue-query")

    // Modify your Vue Query global settings here
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 5000,
          refetchOnWindowFocus: false,
          retryDelay: (failureCount) => Math.min(1000 * 2 ** failureCount, 3000), //
          retry: (failureCount, error) => {
            if (isDevelopment)
              console.error(`[vue-query] retrying query after ${failureCount} failures.`, error)

            switch (true) {
              // We don't want to retry if the error is 401 (unauthorized)
              case error instanceof HTTPError && IGNORE_RETRY_STATUS_CODES.includes(error.response.status):
                return false

              case error instanceof ValiError:
                return false

              case error.name === "TypeError":
                return false

              default:
                return failureCount <= 3
            }
          },
        },
      },
    })
    const options: VueQueryPluginOptions = { queryClient }

    nuxt.vueApp.use(VueQueryPlugin, options)

    if (process.server) {
      nuxt.hooks.hook("app:rendered", () => {
        vueQueryState.value = dehydrate(queryClient)
      })
    }

    if (process.client) {
      nuxt.hooks.hook("app:created", ({ $nuxt }) => {
        findCtfQueriesAndMerge(vueQueryState.value, $nuxt.$i18n)
        hydrate(queryClient, vueQueryState.value)
      })
    }
  },
})

export function findCtfQueriesAndMerge(vueQueryState: DehydratedState | null, $i18n: any) {
  if (!vueQueryState?.queries) return

  const messagesQueries = vueQueryState?.queries
    .filter((q) => q.queryKey.some((k) => String(k).endsWith(queriesConstants.cms)))
    .map((q) => (Array.isArray(q.state.data) ? merge(null, ...q.state.data) : q.state.data))

  if (!messagesQueries?.length) return

  const merged = messagesQueries.length > 1 ? merge(null, ...messagesQueries) : messagesQueries[0]

  $i18n?.mergeLocaleMessage("en", merged?.en)
  $i18n?.mergeLocaleMessage("he", merged?.he)
}
